import React, { useCallback, useEffect, useMemo, useRef, useState, useContext } from "react";
import { LassieContext } from '../../../context/LassieProvider';
import { useParams } from "react-router-dom";
import WplInput from "../../../components/wpl-input/WplInput";
import ShowLessMore from "../../../components/show-lessmore/ShowLessMore";
import WplButton from "../../../components/wpl-button/WplButton";
import { toPrettyDateStr } from "../../../prettyDate";
import WplPopup from "../../../components/wpl-popup-window/WplPopup";
import './complete-turbine-list.css'

export default function CompleteTurbineList() {
    const { fetchTurbines, turbines, createUserAction } = useContext(LassieContext);
    const [seeMoreTurbineIds, setSeeMoreTurbineIds] = useState([]);

    // Show probability FAQ
    const [showProbabilityFAQ, setShowProbabilityFAQ] = useState(false);

    // Selecting filteredTurbines and creating user actions
    const [selectedTurbineIds, setSelectedTurbineIds] = useState([]);
    const [userComment, setUserComment] = useState('');
    const [targetDate, setTargetDate] = useState('');

    const datePickerRef = useRef();
    const openDatePicker = useCallback(() => {
        if (!datePickerRef.current) return;
        datePickerRef.current.showPicker()
    }, [datePickerRef])

    // Turbine filters
    const [filterOnPeak, setFilterOnPeak] = useState(true);
    const [onlyMostProbable, setOnlyMostProbable] = useState(false);
    const [onlyWithoutComment, setOnlyWithoutComment] = useState(false);
    const [peakCurrentRange, setPeakCurrentRange] = useState([-999, 999])
    const [modelFilter, setModelFilter] = useState(null);
    const [statusFilter, setStatusFilter] = useState('');
    const [proximityFilter, setProximityFilter] = useState(2000);

    let { windfarm_id } = useParams();
    if (windfarm_id) {
        windfarm_id = parseInt(windfarm_id)
    }

    useEffect(() => {
        if (!windfarm_id) return;
        fetchTurbines(windfarm_id)
    }, [windfarm_id]);

    const sortTurbines = useCallback((a, b) => {
        const diff = b.probability_of_atleast_one - a.probability_of_atleast_one

        if (Math.round(b.probability_of_atleast_one * 100) === Math.round(a.probability_of_atleast_one * 100)) {
            return Math.max(...b.turbine_lightning.map(tl => tl.probability_of_hit)) - Math.max(...a.turbine_lightning.map(tl => tl.probability_of_hit))
        }
        return diff
    }, []);

    const filteredTurbines = useMemo(() => {
        if (!turbines) return [];
        let res = [...turbines];
        res = res
            .filter(t => !modelFilter || t.model === modelFilter)
            .filter(t => !onlyWithoutComment || !t.user_comment)
            .filter(t => !statusFilter || statusFilter === t.status)

        return res
            .map(t => {
                const filtered_lightning = t.turbine_lightning
                    .filter(l => !onlyMostProbable || l.most_probable)
                    .filter(l => l.peak_current > peakCurrentRange[0] && l.peak_current < peakCurrentRange[1])
                    .filter(l => (l.distance_km * 1000) < proximityFilter)

                const prob = (1 - filtered_lightning.map(tl => (1 - tl.probability_of_hit)).reduce((p, c) => p * c, 1))
                return {
                    ...t,
                    turbine_lightning: filtered_lightning,
                    probability_of_atleast_one: prob
                }
            })
            .filter(t => t.probability_of_atleast_one > 0)
    }, [turbines, filterOnPeak, modelFilter, proximityFilter, onlyMostProbable, statusFilter, peakCurrentRange]);

    const modelOptions = useMemo(() => {
        const models = filteredTurbines.map(t => t.model)

        return models.filter((m, idx) => models.indexOf(m) === idx)
    }, [filteredTurbines]);

    const downloadWorkOrderCSV = useCallback(() => {
        const exportTurbines = filteredTurbines.filter(t => selectedTurbineIds.indexOf(t.id) >= 0).map(t => ({
            name: t.name,
            windfarm: t.windfarm,
            model: t.model,
            user_comment: t.user_comment,
            Probability: Math.round(t.probability_of_atleast_one * 100),
            distance_meters: Math.round(Math.min(...t.turbine_lightning.map(l => parseFloat(l.distance_km))) * 1000),
            iec_certification: t.turbine_lightning.some(tl => tl.outside_certification) ? 'Outside certification' : '',
        }))

        const csvData = 'data:text/csv;charset=utf-8,' + Object.keys(exportTurbines[0]).join(',') + '\n' +
            exportTurbines.map(row => Object.values(row).join(',')).join('\n');

        const link = document.createElement('a');
        link.setAttribute('href', encodeURI(csvData));
        link.setAttribute('download', `${toPrettyDateStr(new Date())}-lassie-work-order.csv`);
        document.body.appendChild(link);
        link.click();
    }, [filteredTurbines, selectedTurbineIds]);

    const downloadLightningCSV = useCallback(() => {
        const exportTurbines = filteredTurbines.filter(t => selectedTurbineIds.indexOf(t.id) >= 0).map(t => (
            t.turbine_lightning.map(s => ({
                windfarm: t.windfarm,
                turbine_name: t.name,
                timestamp_local: toPrettyDateStr(new Date(s.timestamp), true) + '.' + String(Math.round(s.nanosecond / 1e6)).padStart(3, '0'),
                peak_current: Math.round(s.peak_current * 10) / 10,
                distance_meters: Math.round(s.distance_km * 100) * 10,
                semi_major_meters: Math.round(s.semi_major_axis * 100) * 10,
                probability_within_collection_area: Math.round(s.probability_of_hit * 100),
                outside_iec_certification: s.outside_certification
            }))
        ))

        const csvData = 'data:text/csv;charset=utf-8,' + Object.keys(exportTurbines[0][0]).join(',') + '\n' +
            exportTurbines.map(row => row.map(row2 => Object.values(row2).join(',')).join('\n')).join('\n');

        const link = document.createElement('a');
        link.setAttribute('href', encodeURI(csvData));
        link.setAttribute('download', `${toPrettyDateStr(new Date())}-lassie-${exportTurbines[0][0]['windfarm']}.csv`.toLowerCase());
        document.body.appendChild(link);
        link.click();
    }, [filteredTurbines, selectedTurbineIds]);


    const handleMinChange = (e) => {
        const newMin = e.target.value;
        if (newMin === "" || !isNaN(newMin)) {
            setPeakCurrentRange(prevRange => [newMin === "" ? "" : Number(newMin), prevRange[1]]);
        }
    };

    const handleMaxChange = (e) => {
        const newMax = e.target.value;
        if (newMax === "" || !isNaN(newMax)) {
            setPeakCurrentRange(prevRange => [prevRange[0], newMax === "" ? "" : Number(newMax)]);
        }
    };

    const handleDistanceChange = (e) => {
        const newDistance = e.target.value;
        if (newDistance === "" || !isNaN(newDistance)) {
            setProximityFilter(newDistance === "" ? "" : Number(newDistance));
        }
    };

    const handleCreateUserAction = ({ turbine_ids, action, target_date, user_comment }) => {
        createUserAction({
            turbine_ids: selectedTurbineIds,
            action: 'marked_for_inspection',
            target_date: targetDate,
            user_comment: userComment
        })
        setUserComment('');
        setTargetDate('');
        setSelectedTurbineIds([]);
    }

    return (<div className='complete-turbine-list'>
        <WplPopup showPopup={showProbabilityFAQ} closePopup={setShowProbabilityFAQ} className='probability-popup'>
            <h2>Probability in LASSIE</h2>

            <p>Probability in LASSIE refers to the likelihood of a lightning strike occurring within the collection area
                of a turbine. The collection area is defined as three times the height of the turbine.</p>

            <p>It's important to note that a lightning strike within the collection area doesn't necessarily mean that
                the turbine has been hit. There is inherent uncertainty in the measurements, meaning that even with a
                100% probability, it's not certain that the lightning strike occurred within the collection area.</p>

            <p>We hope this clarifies any questions you may have had about the probability calculation in LASSIE.</p>
        </WplPopup>
        <div className='filter-menu'>
            {modelOptions && <WplInput title='Turbine Model'
                options={modelOptions}
                value={modelFilter}
                onChanged={setModelFilter}
                clearable
                onlySelectableOptions />}

            <WplInput title='Turbine Status'
                options={['At risk', 'Marked for inspection', 'Acceptable risk']}
                value={statusFilter.charAt(0).toUpperCase() + statusFilter.substring(1).replace(/_/g, ' ')}
                onChanged={v => setStatusFilter(v.toLowerCase().replace(/ /g, '_'))}
                clearable
                onlySelectableOptions />

            <label>Peak current range: kA</label>
            <div className="peak-area">
                <div className="peak-input">
                    <label htmlFor="">Min</label>
                    <input
                        type="number"
                        value={peakCurrentRange[0]}
                        onChange={handleMinChange}

                    />
                </div>
                <div className="peak-input">
                    <label htmlFor="">Max</label>
                    <input
                        type="number"
                        value={peakCurrentRange[1]}
                        onChange={handleMaxChange}
                    />
                </div>
            </div>

            <label>Distance</label>
            <div className="distance-area">
                <div className="peak-input">

                    <label htmlFor="">Meters</label>
                    <input
                        type="number"
                        value={proximityFilter}
                        onChange={handleDistanceChange}
                        min={0}
                        max={2000}
                    />
                </div>
            </div>


            <div>
                <input id='only-most-prob'
                    type='checkbox'
                    checked={onlyMostProbable} onChange={e => setOnlyMostProbable(e.target.checked)} />
                <label htmlFor='only-most-prob'>Only show most probable strike</label>
            </div>
            <div>
                <input id='no-comment-ckx'
                    type='checkbox'
                    checked={onlyWithoutComment} onChange={e => setOnlyWithoutComment(e.target.checked)} />
                <label htmlFor='no-comment-ckx'>Only show without comment</label>
            </div>

            <hr />
            <label>Selected turbines:</label>
            <div className='selected-turbine-wrapper'>
                {filteredTurbines
                    .filter(t => selectedTurbineIds.indexOf(t.id) >= 0)
                    .map((t, index, arr) => (
                        <div key={t.id}>
                            {t.name}
                            {index !== arr.length - 1 && ', '}
                        </div>
                    ))
                }
            </div>

            <label className='label-comment-or-note' htmlFor='comment-or-note'>Add Comment or note for action</label>
            <textarea id='comment-or-note'
                value={userComment}
                onChange={e => setUserComment(e.target.value)}
            />
            <WplInput
                inputRef={datePickerRef}
                onClick={openDatePicker}
                title='Event date'
                className='performed-inspection-date' type='date'
                value={targetDate}
                onChanged={e => setTargetDate(e)}
            />
            <div className="btn-container">
                <WplButton className='report-btn'
                    big
                    disabled={!targetDate || selectedTurbineIds.length === 0}
                    value='Mark for inspection and keep'
                    onClick={() => handleCreateUserAction({
                        turbine_ids: selectedTurbineIds,
                        action: 'marked_for_inspection',
                        target_date: targetDate,
                        user_comment: userComment
                    })}
                />

                <WplButton className='report-btn'
                    big
                    red
                    disabled={!targetDate || selectedTurbineIds.length === 0}
                    value='Accept risk (reset lightning risk)'
                    onClick={() => handleCreateUserAction({
                        turbine_ids: selectedTurbineIds,
                        action: 'acceptable_risk',
                        target_date: targetDate,
                        user_comment: userComment
                    })}
                />
                <WplButton value='Export lightning to .csv'
                    className='export-btn'
                    onClick={downloadLightningCSV}
                    disabled={selectedTurbineIds.length === 0} />

                <WplButton value='Export work order to .csv'
                    className='export-btn'
                    onClick={downloadWorkOrderCSV}
                    disabled={selectedTurbineIds.length === 0} />
            </div>
        </div>
        <table className="complete-turbine-list-table">
            <thead>
                <tr>
                    <th>  <input
                        type='checkbox'
                        checked={filteredTurbines.every(t => selectedTurbineIds.indexOf(t.id) > -1)}
                        onChange={e => {
                            if (selectedTurbineIds.length === filteredTurbines.length) {
                                setSelectedTurbineIds([]);
                            } else {
                                setSelectedTurbineIds(filteredTurbines.map(t => t.id));
                            }
                        }}
                    />
                    </th>
                    <th>Name</th>
                    <th>Model</th>
                    <th>Comment</th>
                    <th>Probability <label className='prob-question-mark'
                        title='What is probability in LASSIE?'
                        onClick={_ => setShowProbabilityFAQ(true)}>?</label></th>
                    <th>Closest lightning</th>
                    <th>Outside cert</th>
                </tr>
            </thead>
            <tbody>
                {filteredTurbines.sort(sortTurbines).map((t) => <>
                    <tr className='turbine-row' key={t.id}>
                        <td><input type='checkbox' checked={selectedTurbineIds.indexOf(t.id) !== -1} onChange={e => {
                            if (selectedTurbineIds.indexOf(t.id) === -1) {
                                setSelectedTurbineIds(prev => ([...prev, t.id]))
                            } else {
                                setSelectedTurbineIds(prev => prev.filter(p => p !== t.id))
                            }
                        }} /></td>
                        <td>{t.name}</td>
                        <td>{t.model}</td>
                        <td>{t.user_comment}</td>
                        <td>{Math.round(t.probability_of_atleast_one * 100)} %</td>
                        <td>{Math.round(Math.min(...t.turbine_lightning.map(l => parseFloat(l.distance_km))) * 100) * 10} m</td>
                        <td>{t.turbine_lightning.some(tl => tl.outside_certification) ? 'Outside certification' : ''}</td>
                        <td><ShowLessMore showMore={seeMoreTurbineIds.indexOf(t.id) !== -1} setShowMore={_ => {
                            if (seeMoreTurbineIds.indexOf(t.id) === -1) {
                                setSeeMoreTurbineIds(prev => ([...prev, t.id]))
                            } else {
                                setSeeMoreTurbineIds(prev => prev.filter(p => p !== t.id))
                            }
                        }} /></td>
                    </tr>
                    {seeMoreTurbineIds.indexOf(t.id) !== -1 && <tr key={`${t.id}-lightning-headers`}>
                        <th></th>
                        <th>Distance</th>
                        <th>Major axis</th>
                        <th>Peak current</th>
                        <th>Hit probability</th>
                        <th>Timestamp</th>
                    </tr>}
                    {seeMoreTurbineIds.indexOf(t.id) !== -1 && [...t.turbine_lightning]
                        .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)).map(tl =>
                            <tr
                                className={`lightning-row ${tl.outside_certification ? 'outside-cert' : ''}`} key={tl.id}>
                                <td></td>
                                <td>{Math.round(tl.distance_km * 100) * 10} m</td>
                                <td>{Math.round(tl.semi_major_axis * 100) * 10} m</td>
                                <td>{Math.round(tl.peak_current * 10) / 10} kA</td>
                                <td>{Math.round(tl.probability_of_hit * 100)} %</td>
                                <td>{toPrettyDateStr(new Date(tl.timestamp), true)}.{Math.round(tl.nanosecond / 1e6).toString().padStart(3, '0')}</td>
                                {tl.outside_certification ? <td>Outside certification!</td> : null}
                            </tr>)}
                    {seeMoreTurbineIds.indexOf(t.id) !== -1 && <br />}
                </>
                )}
            </tbody>
        </table>
    </div>)
}
