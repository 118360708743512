import React from 'react';
import { toPrettyTimeSince } from "../../../prettyDate";
import icon from "../../../assets/img/icon-info.png"

import './dashboard-card.css';

export default function DashboardCard({ statistics }) {
    if (!statistics) return null;


    const groupedStatistics = statistics.reduce((acc, entry) => {
        const key = entry.cardClusterId ?? entry.title;
        if (!acc[key]) acc[key] = [];
        acc[key].push(entry);
        return acc;
    }, {});

    const sortedGroups = Object.values(groupedStatistics).sort((a, b) => {
        if (a[0].cardClusterId && !b[0].cardClusterId) return 1;
        if (!a[0].cardClusterId && b[0].cardClusterId) return -1;
        return 0;
    });

    return (
        <div className='dashboard-card-wrapper'>
            {sortedGroups.map((group, index) => {
                return (
                    <div key={index} className={`dashboard-card ${group[0].color ? group[0].color : ''}`}>
                        {group.map((entry, subIndex) => {
                            let pretty_value = entry.value;
                            if (entry.format === 'timestamp') {
                                pretty_value = toPrettyTimeSince(new Date(pretty_value));
                            }

                            return (
                                <div key={subIndex} className='entry-container'>
                                    {entry.tooltip ?
                                        <span className='info-tooltip-icon'>
                                            <p className='dashboard-card-title'>{entry.title}</p>
                                            <img src={icon} alt='info-tooltip' />
                                            <p className='info-container'>
                                                {entry.tooltip}
                                            </p>
                                        </span> :
                                        <p className='dashboard-card-title'>{entry.title}</p>}
                                    <div className='value-container'>
                                        {entry.image && <img src={entry.image} className='card-icon' alt='icon' />}
                                        <p className='value'>{pretty_value}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
}
