import React, { useState, useCallback, useEffect } from 'react'
import WplMultiSelect from "../../../components/wpl-multi-select/wpl-multi-select";
import WplInput from "../../../components/wpl-input/WplInput";
import WplButton from "../../../components/wpl-button/WplButton";
import { datamanagementAPI, reportAPI } from "../../../api";
import DmInternalFileSelection from "../internal/DmInternalFileSelection";
import WplFileUpload from "../../../components/wpl-file-upload/WplFileUpload";
import DmCornisFileSelection from "../cornis/DmCornisFileSelection";
import DmSulzerFileSelection from '../sulzer/DmSulzerFileSelection';
import './dm-goose.css'
import DmDroneBaseFileSelection from "../drone_base/DmDroneBaseFileSelection";
import ExternalVideoProcessing from '../external-video-processing/ExternalVideoProcessing';

export default function DmGoose() {
    const [importMode, setImportMode] = useState('WPL (internal video)')
    const [bucketPath, setBucketPath] = useState('')
    const [report, setReport] = useState({});
    const [availableReports, setAvailableReports] = useState([]);

    const [parsedObject, setParsedObject] = useState({})

    // skyspecs
    const [skyspecsTurbines, setSkyspecsTurbines] = useState([]);
    const [skyspecsFileData, setSkyspecsFileData] = useState('');
    const [windfarm, setWindfarm] = useState('');
    const [turbine, setTurbine] = useState('');
    const [customer, setCustomer] = useState('');
    const [inspectionDate, setInspection_date] = useState('');
    const [rotorDiameter, setRotorDiameter] = useState('');
    // skyspecs end

    const pdgParseClicked = useCallback(() => {
        datamanagementAPI.parsePdg(bucketPath, report.id).then(setParsedObject)
    }, [bucketPath, report])

    const skyspecsParseClicked = useCallback(() => {
        datamanagementAPI
            .parseSkyspecsTurbine(
                bucketPath,
                turbine,
                inspectionDate,
                skyspecsFileData,
                report.id
            ).then(PO => {
                setParsedObject(PO)
            })
    }, [bucketPath, turbine, inspectionDate, report, skyspecsFileData]);

    function renderPdg() {
        if (importMode !== 'PDG (Helicopter)') return;
        let bad_img = false;
        if (Object.keys(parsedObject).length > 0) {
            parsedObject.blades.forEach(blade => {
                blade.surfaces.forEach(surface => {
                    surface.images.forEach(img => {
                        if (img.pixel_mm < 0.01) {
                            bad_img = true
                        }
                    })
                })
            })
        }
        return <div>
            <h4>PDG</h4>
            <WplInput onChanged={setBucketPath} value={bucketPath} title='Bucket root' multiSelect={false} />
            {bad_img && <p>THERE IS A BAD IMG</p>}
            <WplButton value='Parse' onClick={pdgParseClicked} />
        </div>
    }

    function skyspecsUploadedFile(filename, data) {
        setSkyspecsFileData(data)
        datamanagementAPI.listSkyspecsTurbines(data).then(setSkyspecsTurbines)
    }

    function renderSkyspecs() {
        if (importMode !== 'Skyspecs (drone)') return;

        const head = [
            <h4>Skyspecs</h4>,
            <WplFileUpload didSelectFile={skyspecsUploadedFile} />
        ];

        if (skyspecsTurbines.length === 0) return head;

        return <div>
            {head}

            <WplInput onChanged={setBucketPath} value={bucketPath} title='Bucket root'
                multiSelect={false} />

            <WplInput onChanged={setWindfarm} value={windfarm} title='windfarm'
                multiSelect={false} />

            <WplInput onChanged={setTurbine} value={turbine} title='Turbine'
                multiSelect={false} options={skyspecsTurbines.sort((a, b) => a.localeCompare(b))}
                onlySelectableOptions={true} />

            <WplInput onChanged={setCustomer} value={customer} title='customer'
                multiSelect={false} />

            <WplInput onChanged={setInspection_date} value={inspectionDate} title='inspectionDate'
                multiSelect={false} />

            <WplInput onChanged={setRotorDiameter} value={rotorDiameter} title='rotorDiameter'
                multiSelect={false} />

            <WplButton value='Parse' onClick={skyspecsParseClicked} disabled={[
                bucketPath,
                windfarm,
                turbine,
                customer,
                inspectionDate,
                report.blade_length,
                rotorDiameter
            ].some(t => t === '')} />
        </div>
    }


    useEffect(() => {
        reportAPI.listReports().then(setAvailableReports)
    }, []);


    useEffect(() => {
        console.log(importMode)
    }, [importMode])

    return (
        <div className='goose-import'>
            <h1>Load into Goose</h1>
            <WplInput title='Select report to import into'
                options={availableReports.map(r => r.title)}
                onChanged={title => setReport(availableReports.find(r => r.title === title))}
                onlySelectableOptions
                value={report.title} />
            {Object.keys(report).length !== 0 && !report.blade_length &&
                <h2 style={{ color: 'red' }}>Blade length is empty on selected report</h2>}

            {report && report.blade_length && <>
                <div className='inspection-company-wrapper'>
                    <WplMultiSelect
                        title='Select inspection company/method' selected={importMode}
                        onSelected={importMode => {
                            setImportMode(importMode)
                            setParsedObject({})
                        }}
                        options={['PDG (Helicopter)', 'Cornis (ground based)', 'Skyspecs (drone)', 'WPL (internal video)', 'WPL (external video)', 'Sulzer & Smith Laboratories', 'Drone Base']}
                        singleSelect={true}
                    />
                </div>
                <br />

                {importMode === 'Cornis (ground based)'
                    && <DmCornisFileSelection setParsedObject={setParsedObject} report_id={report.id}
                        bladeLength={report.blade_length} />}
                {importMode === 'WPL (internal video)'
                    && <DmInternalFileSelection />}

                {importMode === 'Sulzer & Smith Laboratories'
                    && <DmSulzerFileSelection setParsedObject={setParsedObject}
                        report_id={report.id} />}

                {importMode === 'Drone Base'
                    && <DmDroneBaseFileSelection setParsedObject={setParsedObject}
                        report_id={report.id} />}

                {importMode === 'WPL (external video)' && <ExternalVideoProcessing report={report} />}

                {renderPdg()}
                {renderSkyspecs()}
            </>}
        </div>)
}
