import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { documentAPI } from "../../api";
import './document-card.css'
import '../wpl-table/wpl-table.css'
import Card from "../card/Card";
import WplFileUpload from "../wpl-file-upload/WplFileUpload";
import {
    deleteDocument, getTurbineDocuments, getWindfarmDocuments, selectAllDocuments,
    uploadDocument
} from "../../flow/documentFLOW";
import { Buffer } from "buffer";

import deleteIconBlack from '../../assets/img/delete_black.svg';
import deleteIconWhite from '../../assets/img/delete_white.svg';
import WplInput from "../wpl-input/WplInput";
import { DocumentApi } from "../../api_client/Document";
import axios from "axios";

export default function DocumentCard({ windfarm, turbine }) {
    const [searchText, setSearchText] = useState('');

    const dispatch = useDispatch()
    const documents = useSelector(selectAllDocuments)

    useEffect(() => {
        if (!windfarm) return;
        if (turbine) {
            dispatch(getTurbineDocuments(turbine.id))
        } else {
            dispatch(getWindfarmDocuments(windfarm.id))
        }
    }, [dispatch, turbine, windfarm])

    const download = useCallback((id) => {
        documentAPI.download(id).then(url => {
            window.open(url, '_blank')
        })
    }, []);

    const delete_document = useCallback((document) => {
        if (!window.confirm(`Delete ${document.file_name} permanently?`)) return;
        dispatch(deleteDocument(document.id));
    }, []);

    const upload_document = useCallback((file_name, content, callback) => {
        dispatch(uploadDocument({
            file_name,
            content,
            windfarm_id: windfarm?.id,
            turbine_id: turbine?.id,
            callback
        }));
        return true
    }, [windfarm, turbine]);

    if (!windfarm && !turbine) return null;
    return (<Card className='document-card'>
        <div className='header-wrapper'>
            <div>
                <h1>Reports</h1>
                {windfarm && !turbine && <p>{windfarm.name} Documents</p>}
                {turbine && <p>{windfarm.name} - {turbine.name} Documents</p>}
            </div>
            <div>
                <WplFileUpload didSelectFile={upload_document} />
            </div>
        </div>
        <WplInput placeholder='Search documents' className='search-bar' value={searchText} onChanged={setSearchText} />
        <div className='table-wrap'>
            <table className='document-table'>
                <tbody>
                    {documents
                        .filter(d => !searchText || d.file_name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
                        .sort((a, b) => a.file_name.localeCompare(b.file_name)).map(doc => {
                            const searchStart = !searchText || doc.file_name.toLowerCase().indexOf(searchText.toLowerCase())
                            return (<tr key={doc.file_name} onClick={() => download(doc.id)}>
                                <td>{doc.file_name.substring(0, searchStart)}
                                    <span
                                        className='found'>{doc.file_name.substring(searchStart, searchStart + searchText.length)}</span>
                                    {doc.file_name.substring(searchStart + searchText.length)}</td>
                                <td className='delete-btn' onClick={() => delete_document(doc)}>
                                    <img src={deleteIconBlack} className='black' alt={`delete document: ${doc.file_name}`} />
                                    <img src={deleteIconWhite} className='white' alt={`delete document: ${doc.file_name}`} />
                                </td>
                            </tr>)
                        })}
                    {documents.length === 0 && <tr>
                        <td colSpan={3}>No files</td>
                    </tr>}
                </tbody>
            </table>
        </div>
    </Card>)
}
