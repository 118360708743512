import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

export default function PieChartComponent({
  innerRadius,
  outerRadius,
  data,
  colors,
}) {
  const transformedData = data.flatMap(({ text, value }, idx) => {
    const spacerWidth = 0.005;
    if (value <= 0) return [];

    const total = data.reduce((c, p) => p.value + c, 0);
    const val = value / total - spacerWidth;

    return [
      {
        text,
        value: val < 0.02 ? 0.02 : val,
        fill: colors[idx],
      },
      {
        text: 'spacer',
        value: spacerWidth,
        fill: 'white',
      },
    ];
  });

  return (
    <ResponsiveContainer height={300} width={400}>
      <PieChart width={outerRadius * 2} height={outerRadius * 2}>
        <Pie
          data={transformedData}
          dataKey="value"
          nameKey="text"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          isAnimationActive={true}
          label={({ name }) => (name !== 'spacer' ? name : '')}
        >
          {transformedData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fill} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
