import React from 'react';
import './lightning-parent-page.css'
import { Outlet } from "react-router-dom";
import LassieProvider from '../../context/LassieProvider';
import Navbar from '../../components/lassie-navbar/Navbar';

export default function LightningParentPage() {
    return (
        <LassieProvider>
            <div className='lightning-parent-page'>
                <Navbar />
                <div className='content-wrapper'>
                    <Outlet />
                </div>
            </div>
        </LassieProvider>
    )
}
