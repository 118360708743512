import React from 'react'
import './lightning-privacy-page.css'

export default function LightningPrivacyPage() {
  return (
    <div className='privacy-container'>
      <h1>Privacy policy</h1>
      <h2 className='privacy-subtitle'>Owner and contact information</h2>
      <p>The website is owned and published by: </p>
      <br/>
      <h4>Wind Power LAB </h4>
      <br/>
      <p>Address: Købmagergade 22, 1150, Copenhagen K, Denmark </p>
      <br/>
      <p>CVR (central business register): 38281585 </p>
      <p>Tel. no.: +45 30 48 24 48</p>
      <p>CVR (central business register): 38281585</p>
      <p>Email: <a href="mailto:contact@windowerlab.com">contact@windowerlab.com</a></p>
      <p>Website: <a href='https://www.datatilsynet.dk/'>www.datatilsynet.dk</a> </p>


      <h2 className='privacy-subtitle'>Personal Data</h2>

      <p>Personal data is any information that to some extent can be attributed to you. For LASSIE we only collect your first name and your email addresss for sending out notifications when lightning has been near the wind farm you are subscribed to.</p>
      <p>The only data we request is information relevant to your enquiry.</p>

      <h2 className='privacy-subtitle'>Use of personal data</h2>
      <p>Wind Power LAB uses the personal data collected for the following purposes:</p>

      <ul>
        <li>Customer support</li>
        <li>Personal contact</li>
      </ul>

      <h2 className='privacy-subtitle'>Security</h2>
      <p>We have taken all technical and organizational measures to protect your data against accidental or unlawful erasure, loss, misuse, destruction, publication, unauthorized disclosure, or any other unlawful processing.</p>

      <h2 className='privacy-subtitle'>Purpose</h2>

      <p>We only use your personal data to deal with your request, to ensure the quality of our products, to make our services better, and to be able to get in contact with you.</p>


      <h2 className='privacy-subtitle'>Retention Period</h2>

      <p>We only store personal data for as long as it is relevant for us to deal with your request or where filing is required by law.</p>

      <h2 className='privacy-subtitle'>Disclosure</h2>

      <p>Your personal data is safe with us and will not be disclosed to any third party unless you give separate consent to this. Wind Power LAB will always appear as the sender of the e-mails you receive.</p>


      <h2 className='privacy-subtitle'>Your Rights</h2>
      <p>You have the rights to be informed of the personal data we have registered about you. You also have the right to change or revoke your consent at any time. In addition, any personal data registered about you, which we are not required by law to retain may be deleted on your request. Finally, you are entitled to receive the personal data provided by you in a structured, commonly used and machine-readable format (data portability). </p>
      <p>Please write to us at <a href="mailto:contact@windowerlab.com">contact@windowerlab.com</a>, if you want to exercise your rights. </p>

      <h2 className='privacy-subtitle'>Changes</h2>
      <p>Our privacy policy may be changed from time to time. Changes to our privacy policy will be stated on this page. If any significant changes are made you will be informed by email as well. </p>

      <h2 className='privacy-subtitle'>Complaints</h2>
      <p>If you are unhappy with the way, we are working with your data, and we cannot settle this you have the right to lodge a complaint with the Danish Data Protection Agency using the link <a href='https://www.datatilsynet.dk/'>www.datatilsynet.dk</a>. </p>

    </div>
  )
}