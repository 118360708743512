import { http } from "../api";

const easyUnpack = ({ data }) => data;
export const ExternalVideoProcessingApi = {
  video_overview: {
    f: function video_overview(bucket_path) { let url = '/external-video/processing/video-overview'; return http.get(url, { params: { bucket_path } }).then(easyUnpack) }
  },
  // generate_images: {
  //   f: function generate_images(report_id, turbine, blade, overlap, suction_side, leading_edge, pressure_side, trailing_edge) { let url = '/external-video/processing/generate-images'; return http.post(url, { report_id, turbine, blade, overlap, suction_side, leading_edge, pressure_side, trailing_edge }).then(easyUnpack) }
  // },
  flight_plot: {
    f: function flight_plot(suction_side, leading_edge, pressure_side, trailing_edge) { let url = '/external-video/processing/flight-plot'; return http.post(url, { suction_side, leading_edge, pressure_side, trailing_edge }).then(easyUnpack) }
  },
};
