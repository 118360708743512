import React, { useMemo, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { LassieContext } from '../../context/LassieProvider';
import './at-risk-windfarm-list.css'
import { icons_handled } from "../ol-map/icons";
import { icons_at_risk } from "../ol-map/icons";
import windfarmIcon from '../../assets/img/windfarm.png'


export default function WindfarmList({ onlyShowAtRisk = false, onClick = null }) {
    const navigate = useNavigate()

    const { windfarms, setSelectedWindfarm } = useContext(LassieContext)

    const riskWindfarms = useMemo(() => {
        return windfarms.filter(wf => wf.require_attention_count > 0).sort(wf => 1 - wf.require_attention_count)
    }, [windfarms]);

    const noRiskWindfarms = useMemo(() => {
        return windfarms.filter(wf => wf.require_attention_count === 0)
    }, [windfarms]);


    const wfs = useMemo(() => {
        if (onlyShowAtRisk) return riskWindfarms
        return windfarms
    }, [onlyShowAtRisk, windfarms, riskWindfarms]);

    const handleSelectWindfarm = (windfarm) => {
        navigate(`windfarm/${windfarm.id}`)
        setSelectedWindfarm(windfarm)
    }

    return (
        <div className='windfarm-list-container'>
            <h1>Welcome to LASSIE</h1>
            <h4>Select a windfarm</h4>

            <div className='windfarm-list'>
                {riskWindfarms.length > 0 && <>

                    {riskWindfarms.map(wf => <div
                        key={wf.id}
                        onClick={_ => onClick ? onClick(wf) : handleSelectWindfarm(wf)}
                        className={`windfarm ${wf.require_attention_count > 0 ? 'at-risk' : ''}`}>
                        <div className="icon-wrapper">
                            <img className='windfarm-icon' alt='Turbines icon' src={windfarmIcon} />
                            <p className='windfarm-name'>{wf.name}</p>
                        </div>
                        <div className='icon-wrapper'>
                            <img className='status-icon' alt='Turbines on this windfarm has had lightning activity' src={icons_at_risk.High} />
                            <label> {wf.require_attention_count}{' '}Turbines at risk</label>
                        </div>
                        <div className='windfarm-data-wrapper'>
                        </div>
                    </div>)}
                </>}
                <hr className='spacer' />
            </div>
            <div className='windfarm-list'>
                {noRiskWindfarms.length > 0 &&
                    <>
                        {noRiskWindfarms.map(wf => <div
                            key={wf.id}
                            onClick={_ => onClick ? onClick(wf) : handleSelectWindfarm(wf)}
                            className={`windfarm ${wf.require_attention_count > 0 ? 'at-risk' : ''}`}>
                            <div className='icon-wrapper'>
                                <img className='windfarm-icon' alt='Turbines icon' src={windfarmIcon} />
                                <p className='windfarm-name'>{wf.name}</p>
                            </div>
                            <div className='icon-wrapper'>
                                <img className='status-icon' alt='Turbines on this windfarm has had lightning activity' src={icons_handled} />
                                <label>Managed</label>
                            </div>
                            <div className='windfarm-data-wrapper'>
                            </div>
                        </div>)}
                    </>
                }
                {wfs.length === 0 && <h3>Loading...</h3>}


            </div>
        </div>
    )
}
