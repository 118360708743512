export const extractUniqueYears = (data) => [...new Set(data.map(item => item.year))];

export const processDataForCharts = (data, selectedYear) => {   
    const monthlyLightningData = {};
    const monthlyDayData = {};
    const totalLightningCounts = Array(12).fill(0);
    const totalDayCounts = Array(12).fill(0);
    const yearCounts = {};
    const maxLightningCountPerMonth = {};
    const maxDaysCountPerMonth = {};
    
    data.forEach(item => {
      const { month, year, lightning_count, days } = item;
      
      if (!yearCounts[year]) {
        yearCounts[year] = { lightning: Array(12).fill(0), days: Array(12).fill(0) };
      }
      
      yearCounts[year].lightning[month - 1] += lightning_count;
      yearCounts[year].days[month - 1] += days;
      
      totalLightningCounts[month - 1] += lightning_count;
      totalDayCounts[month - 1] += days;
      
      maxLightningCountPerMonth[month - 1] = Math.max(maxLightningCountPerMonth[month - 1] || 0, lightning_count);
      maxDaysCountPerMonth[month - 1] = Math.max(maxDaysCountPerMonth[month - 1] || 0, days);
    });
    
    Object.keys(yearCounts).forEach(year => {
      monthlyLightningData[year] = yearCounts[year].lightning.map((count, i) => ({
        month: i + 1,
        lightning_count: count
      }));
      
      monthlyDayData[year] = yearCounts[year].days.map((count, i) => ({
        month: i + 1,
        days: count
      }));
    });
    
    const numberOfYears = Object.keys(yearCounts).length;
    
    const averageLightningData = totalLightningCounts.map((total, i) => ({
      month: i + 1,
      lightning_count: total / numberOfYears
    }));
    
    const averageDayData = totalDayCounts.map((total, i) => ({
      month: i + 1,
      days: total / numberOfYears
    }));
    
    const maxLightningCount = Math.max(...Object.values(maxLightningCountPerMonth));
    const maxDaysCount = Math.max(...Object.values(maxDaysCountPerMonth));
    
    return { 
      monthlyLightningData, 
      monthlyDayData, 
      averageLightningData, 
      averageDayData,
      maxLightningCount, 
      maxDaysCount 
    };
  };
  