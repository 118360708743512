import axios from 'axios';
import { userService } from './auth/user-service';
import { LassieScenarioApi } from './api_client/LassieScenario';
import { LassieRegionTaskmasterApi } from './api_client/LassieRegionTaskmaster';
import { LightningUserAccessApi } from './api_client/LightningUserAccess';
import { LassieApi } from './api_client/Lassie';
import { LightningReportApi } from './api_client/LightningReport';
import { DemApi } from './api_client/Dem';
import { LassieRegionApi } from './api_client/LassieRegion';
import { GoosePictureApi } from './api_client/GoosePicture';
import { SegmentationApi } from './api_client/Segmentation';
import { ReportApi } from './api_client/Report';
import { DataManagementApi } from './api_client/DataManagement';
import { InternalVideoProcessingApi } from './api_client/InternalVideoProcessing';
import { ExternalVideoProcessingApi } from './api_client/ExternalVideoProcessing';
import { WdExportApi } from './api_client/WdExport';
import { WdImageApi } from './api_client/WdImage';
import { WdDefectApi } from './api_client/WdDefect';
import { WdTurbineApi } from './api_client/WdTurbine';
import { WdWindfarmApi } from './api_client/WdWindfarm';
import { UserApi } from './api_client/User';
import { DocumentApi } from './api_client/Document';

export const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5000',
    timeout: 45000
});

http.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    const location = window.location;
    const isLoginUrl =
        location.toString().toLowerCase().indexOf('/login') !== -1;

    if (token && !isLoginUrl) {
        const expires = localStorage.getItem('tokenExpires');
        if (expires) {
            if (new Date(expires) < new Date()) {
                userService.logout();
                window.location = '/login';
                throw new axios.Cancel('Token expired. Moving to login.');
            }
        }
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});

http.interceptors.response.use(null, err => {
    if (err && err.response && err.response.status === 401) {
        console.log('logging out!');
        userService.logout(true);
        window.location = '/login';
    }
    throw err;
});

const easyUnpack = ({ data }) => data;

const easyCatch = err => {
    throw err;
};

export const baseURL = http.defaults.baseURL;

export const documentAPI = {
    async upload(filename, content, windfarm_id, turbine_id) {
        const ct = {
            png: 'image/png',
            tiff: 'image/tiff',
            jpg: 'image/jpeg',
            pdf: 'application/pdf',
            xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }[filename.split('.').pop()];

        const { signed_url, bucket_path } = await DocumentApi.prepare_upload.f(
            filename,
            windfarm_id,
            { turbine_id }
        );

        // Upload file
        await axios
            .put(signed_url, content, {
                headers: {
                    'Content-Type': ct
                }
            })
            .then(easyUnpack);

        return DocumentApi.register_upload.f(filename, bucket_path, {
            windfarm_id,
            turbine_id
        });
    },
    download(document_id) {
        return DocumentApi.download_document.f(document_id);
    },
    delete(document_id) {
        return DocumentApi.patch_document.f(document_id, true);
    },
    getWindfarmDocuments(windfarm_id) {
        return DocumentApi.get_windfarm_documents.f(windfarm_id);
    },
    getTurbineDocuments(turbine_id) {
        return DocumentApi.get_turbine_documents.f(turbine_id);
    }
};

export const userAPI = {
    login(username, password) {
        return UserApi.authenticate.f(username, password);
    },
    addUserToWindfarm(user_id, windfarm_id) {
        return UserApi.add_windfarm_to_user.f(user_id, windfarm_id);
    },
    removeUserFromWindfarm(user_id, windfarm_id) {
        return UserApi.remove_windfarm_from_user.f(user_id, windfarm_id);
    },
    createInvitation(email, user_name, windfarms) {
        return UserApi.create_invitation.f(
            user_name,
            email,
            'username',
            windfarms,
            false
        );
    },
    acceptInvitation(token, password) {
        return UserApi.accept_invitation.f(token, password);
    },
    checkInvitationToken(token) {
        return UserApi.check_invitation_token_validity.f(token);
    },
    requestPasswordReset(email) {
        return UserApi.request_pw_reset.f(email);
    },
    createResetPasswordRequest(user_id) {
        return UserApi.request_pw_reset_admin.f(user_id);
    },
    resetPassword(token, password) {
        return UserApi.accept_pw_reset.f(token, password);
    },
    updateScope(target_user_id, scope) {
        return UserApi.update_user_scope.f(target_user_id, { scope });
    },
    listUsers(with_windfarms) {
        return UserApi.list_users.f({ with_windfarms });
    },
    listInvitations() {
        return UserApi.list_invitations.f();
    },
    listUserWindfarms(user_id) {
        return UserApi.list_user_windfarms.f(user_id);
    }
};

export const windfarmAPI = {
    getWindfarms(with_details = false) {
        return WdWindfarmApi.get_user_windfarms.f(with_details);
    },
    listAllWindfarms() {
        return WdWindfarmApi.list_all_windfarms.f();
    },
    getById(windfarm_id) {
        return WdWindfarmApi.get_by_search.f({ windfarm_id });
    },
    getByName(windfarm_name) {
        return WdWindfarmApi.get_by_search.f({ windfarm_name });
    },
    createWindfarm(name, customer, report_id = null) {
        return WdWindfarmApi.create_windfarm.f(name, customer, { report_id });
    },
    createWindfarmWithDetails(windfarm_data) {
        const {
            name,
            customer,
            oem,
            model,
            production,
            hub_height,
            blade_length,
            blade_type,
            commisioning_year,
            country
        } = windfarm_data;
        return WdWindfarmApi.create_windfarm_details.f(
            name,
            customer,
            oem,
            model,
            production,
            hub_height,
            blade_length,
            blade_type,
            commisioning_year,
            country
        );
    },
    createTurbineForWindfarm(windfarm_id, turbine_name, inspection_date) {
        return WdWindfarmApi.create_turbine_for_windfarm.f(
            windfarm_id,
            turbine_name,
            inspection_date
        );
    },
    listTurbines(windfarm_id) {
        return WdWindfarmApi.get_list_of_turbines_for_windfarm.f(windfarm_id);
    }
};

export const turbineAPI = {
    createTurbine(turbine) {
        const {
            windfarm_id,
            rdspp,
            turbine_name,
            blade_length,
            inspection_date,
            valid_to = null
        } = turbine;
        return WdTurbineApi.create_turbine.f(
            windfarm_id,
            rdspp,
            turbine_name,
            blade_length,
            inspection_date,
            {
                valid_to
            }
        );
    }
};

export const defectAPI = {
    createDefect(data) {
        const {
            turbine_id,
            defect,
            image_source,
            defect_image_data,
            blade_image_data
        } = data;
        return WdDefectApi.create_defect.f(turbine_id, defect, image_source, {
            defect_image_data,
            blade_image_data
        });
    },
    getDefects() {
        return WdDefectApi.base_view.f();
    },
    getWindfarmDefects(windfarm_id) {
        return WdDefectApi.list_windfarm_defects.f(windfarm_id, {});
    },
    getTurbineDefects(turbine_id) {
        return WdDefectApi.list_turbine_defects.f(turbine_id);
    },
    getDefectDetails(defect_id) {
        return WdDefectApi.get_defect_details.f(defect_id);
    },
    getBladeDefects(turbine_id, blade, inspection_date) {
        return WdDefectApi.list_blade_defects.f(blade, turbine_id, {
            inspection_date
        });
    },
    setCustomerNote(defect_id, customer_note) {
        return WdDefectApi.update_defect.f(defect_id, { customer_note });
    },
    setBudgetNote(defect_id, budget_note) {
        return WdDefectApi.update_defect.f(defect_id, {
            budget_note
        });
    },
    setDowntimeNote(defect_id, downtime_note) {
        return WdDefectApi.update_defect.f(defect_id, {
            downtime_note
        });
    },
    setMarkForRepair(defect_id) {
        return WdDefectApi.update_defect.f(defect_id, {
            mark_for_repair: true
        });
    },
    setRepaired(defect_id) {
        return WdDefectApi.update_defect.f(defect_id, {
            repaired: true
        });
    },
    markAsIgnore(defect_id) {
        return WdDefectApi.update_defect.f(defect_id, {
            ignored: true
        });
    },
    getDefectPosition(report_defect_id) {
        return WdDefectApi.get_defect_image_position.f(report_defect_id);
    }
};

export const imageAPI = {
    getDefectImageUrl(defect_id) {
        return WdImageApi.get_defect_img.f(defect_id);
    },
    getDefectBladeImageUrl(defect_id) {
        return WdImageApi.get_blade_img.f(defect_id);
    }
};

export const exportAPI = {
    startExport(defect_ids, skip_images = false) {
        return WdExportApi.start_export_defects.f(defect_ids, skip_images);
    },
    getDownloadLink(export_id) {
        return WdExportApi.get_download_url.f(export_id);
    }
};

export const internalVideoProcessingAPI = {
    recursivelySearchForInspection(root_bucket_path) {
        return InternalVideoProcessingApi.find_inspections.f(root_bucket_path);
    },
    parseLog(inspection_values) {
        return InternalVideoProcessingApi.parse_log.f(inspection_values);
    },
    createTrajectory(inspection_values) {
        return InternalVideoProcessingApi.generate_3d_map.f(inspection_values);
    }
};

export const ExternalVideoProcessingAPI = {
    videoOverview(bucket_path) {
        return ExternalVideoProcessingApi.video_overview.f(bucket_path);
    },
    flightPlot(suction_side, leading_edge, pressure_side, trailing_edge) {
        return ExternalVideoProcessingApi.flight_plot.f(suction_side, leading_edge, pressure_side, trailing_edge)
    }
};

export const datamanagementAPI = {
    getFolderStructure(root, wanted_file_types) {
        return DataManagementApi.folder_structure.f(root, wanted_file_types);
    },
    parseReport(report_id) {
        return DataManagementApi.parse_goose_report.f(report_id);
    },
    parseCornis(bucket_path, json_name, blade_length) {
        return DataManagementApi.parse_cornis.f(
            bucket_path,
            json_name,
            blade_length
        );
    },
    commitCornis(object_data) {
        return DataManagementApi.commit_importable_object.f(object_data);
    },
    parseSulzer(bucket_path, xml_name, report_id) {
        return DataManagementApi.parse_sulzer.f(bucket_path, report_id);
    },
    parseDroneBase(bucket_path, json_name, report_id) {
        return DataManagementApi.parse_drone_base.f(bucket_path, report_id);
    },
    listOverview() {
        return DataManagementApi.get_overview.f();
    },
    parsePdg(bucket_path, report_id) {
        return DataManagementApi.parse_pdg.f(bucket_path, report_id);
    },
    parseSelfServiceDefectExcel(filename, file_data) {
        return DataManagementApi.parse_defect_excel.f(file_data);
    },
    listInternalOverview(bucket_path) {
        return DataManagementApi.list_internal_videos.f(bucket_path);
    },
    encodeVideoToMp4(movie_path) {
        return DataManagementApi.encode_to_mp4.f(movie_path);
    },
    stabilizeVideo(movie_path) {
        return DataManagementApi.stabilize_video.f(movie_path);
    },
    convertCr2ToJpg(bucket_path) {
        return DataManagementApi.parse_cr2_to_jpg.f(
            bucket_path.replace('.jpg', '.CR2')
        );
    },
    listSkyspecsTurbines(csv_file_data) {
        return DataManagementApi.list_turbines_from_skyspecs_file.f(
            csv_file_data
        );
    },
    parseSkyspecsTurbine(
        bucket_path,
        turbine,
        inspection_date,
        file_data,
        report_id
    ) {
        return DataManagementApi.parse_skyspecs.f(
            bucket_path,
            turbine,
            inspection_date,
            file_data,
            report_id
        );
    },
    parseSelfServiceDefectPdfForRepairRecommendation(filename, file_data) {
        return DataManagementApi.parse_inspection_pdf_for_repair_recs.f(
            filename,
            file_data
        );
    },
    parseSelfServiceDefectPdf(filename, file_data) {
        return DataManagementApi.parse_inspection_pdf.f(filename, file_data);
    },
    getSkaliaZip(parsedData) {
        return DataManagementApi.create_xlsx_from_defects.f(parsedData);
    }
};

export const reportAPI = {
    listReports() {
        return ReportApi.list_reports.f();
    }
};

export const segmentationAPI = {
    listNewStatus(report_id) {
        return SegmentationApi.list_report_status.f(report_id);
    },
    listRfModelStatus(report_id) {
        return SegmentationApi.list_report_status.f(report_id);
    },
    listBackgrounds(filters) {
        const { windfarm, turbine, blade } = filters;
        return SegmentationApi.list_backgrounds_for_picture_ids.f(
            blade,
            windfarm,
            turbine
        );
    },
    deleteSegmentationBackgrounds(
        report_id,
        turbine,
        blade,
        surface,
        mask_type
    ) {
        return SegmentationApi.delete_masks.f(
            report_id,
            turbine,
            blade,
            surface,
            mask_type
        );
    },
    getAllUrls(report_id, turbine, blade, surface) {
        return SegmentationApi.list_signed_urls.f(
            report_id,
            turbine,
            blade,
            surface
        );
    },
    QC(segmentation_background_id, quality) {
        return SegmentationApi.set_mask_qc.f(
            segmentation_background_id,
            quality
        );
    }
};

export const pictureAPI = {
    countPictures({ windfarm, turbine, blade, surface }) {
        return GoosePictureApi.count_pictures.f({
            windfarm,
            surface,
            turbine,
            blade
        });
    },
    listUniqueFilters() {
        return GoosePictureApi.list_unique_filters.f();
    }
};

export const lightningAPI = {
    getStatus(date) {
        date = new Date(date);
        return LassieApi.daily_status.f(
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate()
        );
    },
    getStatistics(filters) {
        const { windfarm_id = null, turbine_id = null } = filters;
        return LassieApi.windfarm_statistics.f({ windfarm_id, turbine_id });
    },
    getLatestUserAction(turbine_id) {
        return LassieApi.get_latest_turbine_action.f(turbine_id);
    },
    getTurbineActivity(turbine_id) {
        return LassieApi.turbine_activity_history.f(turbine_id);
    },
    getLatestDataAcquisition(windfarm_id) {
        return LassieApi.latest_data_acquisition.f({ windfarm_id });
    },
    listLightningWindfarms() {
        return LassieApi.list_windfarms_by_user_id.f();
    },
    listAllLightningTurbines(windfarm_id = null, turbine_id = null) {
        return LassieApi.list_turbines.f({ windfarm_id, turbine_id });
    },
    listTurbinesByWindfarmId(wf_id) {
        return LassieApi.list_lightning_subscribed_turbines_by_windfarm_id.f(
            wf_id
        );
    },
    listRegions(region_ids, limit_history_days = null) {
        return LassieRegionApi.list_regions.f({
            region_ids,
            limit_history_days
        });
    },
    listQueriesByRegion(region_id) {
        return LassieRegionApi.list_regional_queries.f(region_id);
    },
    listTurbinesByRegion(region_id) {
        return LassieRegionApi.list_regional_turbines.f(region_id);
    },
    listWindfarmRisktimeline(windfarm_id) {
        return LassieApi.get_risk_timeline.f(windfarm_id);
    }
};

export const lassieDashboardAPI = {
    listStatistics(from_date, to_date, windfarm_id) {
        return LassieApi.dashboard_statistics.f(from_date, to_date, {
            windfarm_id
        });
    },
    listTurbineStatistics(from_date, to_date, windfarm_id) {
        return LassieApi.dashboard_turbine_statistics.f(from_date, to_date, {
            windfarm_id
        });
    },
    listResponseStatistics(from_date, to_date) {
        return LassieApi.dashboard_response_statistics.f(from_date, to_date);
    },
    listScenarioStatistics() {
        return LassieApi.dashboard_scenario_statistics.f();
    },
    listNotificationOverview(windfarm_id) {
        return LassieApi.list_notifications.f(windfarm_id);
    },
    listWindfarmsHistoricUserAction(windfarm_id, from_timestamp, to_timestamp) {
        return LassieApi.get_historic_user_action.f(
            from_timestamp,
            to_timestamp,
            { windfarm_id }
        );
    },
    listTurbineHistoricUserAction(turbine_id, from_timestamp, to_timestamp) {
        return LassieApi.get_historic_user_action.f(
            from_timestamp,
            to_timestamp,
            { turbine_id }
        );
    },
    listHistoricTriggers(windfarm_id, from_timestamp, to_timestamp) {
        return LightningReportApi.historic_trigger.f(
            windfarm_id,
            from_timestamp,
            to_timestamp
        );
    },
    listHistoricActivity({ windfarm_id, from_timestamp, to_timestamp }) {
        return LassieApi.list_turbines_with_historic_lightning_activity.f(
            from_timestamp,
            to_timestamp,
            windfarm_id
        );
    },
    listHistoricLightningActivity({ from_timestamp, to_timestamp, windfarm_id, turbine_id }) {
        return LassieApi.historic_lightning_activity.f(from_timestamp, to_timestamp, windfarm_id, turbine_id)
    },
    listSeasonalStatistics(windfarm_id) {
        return LassieApi.dashboard_seasonality_statistics.f(windfarm_id);
    }
};

export const lightningUserActionAPI = {
    insert_user_action(
        turbine_ids,
        action,
        user_comment,
        target_date,
        file_names = null,
        file_datas = null
    ) {
        return LassieApi.insert_user_action.f(turbine_ids, action, {
            target_date,
            user_comment,
            file_names,
            file_datas
        });
    },
    delete_user_action(user_action_id) {
        return LassieApi.delete_user_action.f(user_action_id);
    }
};

export const lightningSubscriberAPI = {
    list_all_lightning_subscribers() {
        return LightningUserAccessApi.list_all_lightning_subscribers.f();
    },
    list_user_subscriptions_by_user_id(user_id) {
        return LightningUserAccessApi.list_user_subscriptions_by_user_id.f(
            user_id
        );
    },
    subscribe(user_id, windfarm_id) {
        return LightningUserAccessApi.subscribe.f(windfarm_id, user_id);
    },
    unsubscribe(user_id, windfarm_id) {
        return LightningUserAccessApi.unsubscribe.f(windfarm_id, user_id);
    }
};

export const lightningSeverityAPI = {
    insert_ruleset(name, description) {
        return http
            .post(`/lassie/severity/ruleset`, {
                name,
                description
            })
            .then(easyUnpack);
    },
    update_ruleset(ruleset_id, name, description) {
        return http
            .patch(`/lassie/severity/ruleset/${ruleset_id}`, {
                ruleset_id,
                name,
                description
            })
            .then(easyUnpack);
    },
    insert_rule(
        ruleset_id,
        peak_current_from,
        peak_current_to,
        covers_pulse,
        severity
    ) {
        return http
            .post(`/lassie/severity/ruleset/${ruleset_id}`, {
                peak_current_from,
                peak_current_to,
                covers_pulse,
                severity
            })
            .then(easyUnpack);
    },
    list_all_rulesets() {
        return http.get(`/lassie/severity/ruleset`).then(easyUnpack);
    },
    list_rules_by_ruleset_id(ruleset_id) {
        return http
            .get(`/lassie/severity/ruleset/${ruleset_id}/rules`)
            .then(easyUnpack);
    },
    set_ruleset_for_turbine(turbine_id, ruleset_id) {
        return http
            .patch(
                `/lassie/severity/ruleset/${ruleset_id}/turbine/${turbine_id}`
            )
            .then(easyUnpack);
    }
};

export const lightningScenarioAPI = {
    list_scenarios_by_windfarm_id(wf_id) {
        return LassieScenarioApi.list_scenarios.f(wf_id);
    },
    create_scenario_trigger(scenario_id, values) {
        return LassieScenarioApi.create_lassie_trigger.f(scenario_id, values);
    },
    subscribe_to_scenario(scenario_id, frequency) {
        return LassieScenarioApi.base_view.f(frequency, scenario_id);
    },
    unsubscribe_from_scenario(scenario_id, frequency) {
        return LassieScenarioApi.delete_user_subscription.f(
            frequency,
            scenario_id
        );
    },
    create_scenario(values) {
        const { title, immediate_action, description, windfarm_id } = values;
        return LassieScenarioApi.create_lassie_scenario.f(windfarm_id, title, {
            immediate_action,
            description
        });
    }
};

export const lassieTaskmasterLogAPI = {
    list_logs(region_id) {
        return LassieRegionTaskmasterApi.get_logs.f(region_id);
    }
};

export const lassieTaskmasterAPI = {
    run_on_historic_period(region_id, from_timestamp, to_timestamp) {
        return LassieRegionTaskmasterApi.download_data_historic.f(
            region_id,
            from_timestamp,
            to_timestamp
        );
    }
};

export const demAPI = {
    get_turbine_dem_embedded(turbine_id) {
        return DemApi.get_embedded_turbine_scene.f(turbine_id);
    }
};
