import { useEffect } from 'react';
import { useContext } from 'react';
import { LassieContext } from '../context/LassieProvider';

export function useSyncSelectedWindfarm(windfarm_id) {
    const { selectedWindfarm, setSelectedWindfarm } = useContext(LassieContext);

    useEffect(() => {
        if (!windfarm_id || !selectedWindfarm) return;
        if (windfarm_id !== selectedWindfarm.id) {
            setSelectedWindfarm(windfarm_id);
        }
    }, [windfarm_id, selectedWindfarm, setSelectedWindfarm]);
}
