import React from 'react';
import WplSwitch from '../wpl-switch/wplSwitch';
import WplTwoButtonInput from '../wpl-two-button-input/wplTwoButtonInput';
import settingsIcon from '../../assets/img/settings-icon.png';
import infoIcon from '../../assets/img/map-info.png';
import './map-settings.css';

export default function MapSettings({ layerSettings, setLayerSettings, popoutSettingsOpen, setPopoutSettingsOpen, setQaOpen, setPopoutReferencesOpen, popoutReferencesOpen }) {
    const switchSettings = [
        { title: 'Lightning layer', key: 'lightning' },
        { title: 'Only show outside IEC', key: 'only_show_outside_iec', tooltip: 'Enable the Lightning layer to use this feature.' },
        { title: 'Strikes for nearest turbine', key: 'most_probable' },
        { title: 'Collection area', key: 'collection_area' },
    ];

    const handleClick = () => {
        setPopoutSettingsOpen(!popoutSettingsOpen);
        if (popoutReferencesOpen) { setPopoutReferencesOpen(false); }
    };

    return (
        <div className="popout-wrapper">
            <div className="settings-label-container">
                {popoutSettingsOpen && (
                    <div className='settings-container'>
                        {switchSettings.map(({ title, key, tooltip }) => (
                            <WplSwitch
                                key={key}
                                title={title}
                                value={layerSettings[key]}
                                onChanged={(v) => setLayerSettings((prev) => ({ ...prev, [key]: v }))}
                                className="setting-input"
                                tooltip={tooltip}
                            />
                        ))}
                        <WplTwoButtonInput
                            title="Background"
                            options={['topographic', 'imagery']}
                            value={layerSettings.background}
                            onChanged={(v) => setLayerSettings((prev) => ({ ...prev, background: v }))}
                            className="setting-background"
                        />
                    </div>
                )}
                <div className='actions-container'>
                    <div className='settings-icons-container' onClick={() => handleClick()}>
                        <img src={settingsIcon} className="settings-icon" alt="settings icon" />
                        <div
                            className={`arrow-icon ${popoutSettingsOpen ? 'open' : 'closed'}`}
                        ></div>
                    </div>
                    {popoutSettingsOpen && (
                        <img
                            src={infoIcon}
                            className="info-icon"
                            alt="Information icon"
                            onClick={() => setQaOpen({ status: true, type: 'settings' })}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
