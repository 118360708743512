import React, { useState, useRef, useEffect } from 'react'
import {
    timeFormat,
    select,
    line,
    curveCardinal,
    curveLinear,
    scaleLinear,
    axisBottom,
    axisLeft,
} from "d3";
import WplInput from "../../../components/wpl-input/WplInput";
import Card from "../../../components/card/Card";
import './risk-timeline-graph.css'
import loadingSvg from '../../../assets/loading.svg';


export default function RiskTimelineGraph({ riskTimeline }) {
    const [selectedTimeline, setSelectedTimeline] = useState('');
    const [loading, setLoading] = useState(false)

    const svgRef = useRef();
    const riskGraphRef = useRef();


    useEffect(() => {
        setLoading(true)
        if (riskTimeline)
            setLoading(false)
    }, [riskTimeline])

    useEffect(() => {
        if (!svgRef.current || !riskGraphRef.current || !riskTimeline) return;
        if (!selectedTimeline) return;

        const graph = riskTimeline.lines.find(line => line.name === selectedTimeline)

        const svg = select(svgRef.current);
        const svgWrapper = select(riskGraphRef.current);

        const pixelWidth = 900;
        const pixelHeight = 350;

        //scales
        const xScale = scaleLinear()
            .domain([new Date(riskTimeline.min_x), new Date(riskTimeline.max_x)])
            .range([0, pixelWidth]);

        const maxY = graph.max_y * 1.2
        const yScale = scaleLinear()
            .domain([riskTimeline.min_y, maxY])
            .range([pixelHeight, 0]);

        //axes
        const xAxis = axisBottom(xScale).ticks(7, "s").tickFormat(timeFormat("%b %d")); // %b %d %H:%M
        svg.select(".x-axis").call(xAxis);

        const yAxis = axisLeft(yScale);
        svg.select(".y-axis").style("transform", "translateX(0px)").call(yAxis);

        //line generator
        const dLine = line()
            .x(d => xScale(new Date(d.x)))
            .y(d => yScale(d.y))
            .curve(curveLinear);

        svg
            .selectAll(`.line-${graph.id}`)
            .data([graph.data])
            .join("path")
            .attr("class", "line")
            .attr("d", dLine)
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", "2px");

        // draw x axis
        svg
            .selectAll(".x-axis")
            .data([0])
            .join("g")
            .attr("class", "x-axis")
            .attr("transform", "translate(0, 350)")
            .call(xAxis);

        // draw y axis
        svg
            .selectAll(".y-axis")
            .data([0])
            .join("g")
            .attr("class", "y-axis")
            .call(yAxis);

        // display vertical and horizontal guide lines
        svgWrapper
            .append("div")
            .attr('class', 'vertical')
            .style("pointer-events", "none")
            .style("position", "absolute")
            .style("width", "1px")
            .style("top", "0px")
            .style("bottom", "50px")
            .style('display', 'none')
            .style("background", "black");

        svgWrapper
            .append("div")
            .attr('class', 'horizontal')
            .style("pointer-events", "none")
            .style("position", "absolute")
            .style("height", "1px")
            .style("left", "0px")
            .style("right", "50px")
            .style('display', 'none')
            .style("background", "black");

        // draw zones
        const zones = [0.3, 0.6, maxY]
        const zoneColors = [
            "rgba(136, 177, 139, 0.3)",
            "rgba(230, 157, 115, 0.3)",
            "rgba(212, 106, 106, 0.3)",
        ];
        let prevZone = riskTimeline.min_y;
        zones.forEach((zone, idx) => {
            svgWrapper
                .append("div")
                .attr('class', 'zone')
                .style("position", "absolute")
                .style("left", "0px")
                .style("right", "50px")
                .style("top", yScale(zone) + "px")
                .style("height", yScale(prevZone) - yScale(zone) + "px")
                .style("background", zoneColors[idx]);

            prevZone = zone;
        });

        svgWrapper.on("mousemove", e => {
            const boundingRect = svgWrapper.node().getBoundingClientRect();
            const vert = svgWrapper.selectAll('.vertical');
            const hori = svgWrapper.selectAll('.horizontal');

            if (!['zone', 'line-legend'].includes(e.target.className)) {
                vert.style("display", "none")
                hori.style("display", "none")
                return;
            }
            let mousex = e.clientX - boundingRect.left;
            vert.style("left", mousex + "px")
                .style("display", "block");

            let mousey = e.clientY - boundingRect.top;
            hori.style("top", mousey + "px")
                .style("display", "block");
        })

        svgWrapper.on("mouseleave", e => {
            svgWrapper.selectAll('.vertical').style("display", "none")
            svgWrapper.selectAll('.horizontal').style("display", "none")
        })

        return () => {
            svgWrapper.selectAll(".vertical").remove();
            svgWrapper.selectAll(".horizontal").remove();
            svg
                .selectAll('.line')
                .remove();

            svgWrapper
                .selectAll('.zone')
                .remove();
        }
    }, [riskTimeline, svgRef.current, riskGraphRef.current, selectedTimeline]);


    return (
        <div className='risk-graph-card'>
            {riskTimeline &&
                <WplInput
                    title='Select a turbine'
                    value={selectedTimeline}
                    onChanged={setSelectedTimeline}
                    options={riskTimeline.lines.map(line => line.name).sort((a, b) => a.localeCompare(b))}
                    onlySelectableOptions
                    style={{ width: '300px' }}
                />
            }
            {!loading ?
                <div>
                    <div className='risk-graph' style={{ opacity: selectedTimeline ? '1' : '0' }} ref={riskGraphRef}>
                        <svg ref={svgRef}>
                        </svg>
                        <div className='line-legend'>
                            <div className='line-legend-item' title="Your theoretical risk">
                                <div className='line-legend-color' style={{ background: 'black' }} />
                                <label>Actual risk</label>
                            </div>
                            <div className='zone-legend-item' title="High risk zone">
                                <div className='zone-legend-color' style={{ background: 'rgba(212, 106, 106, 0.8)' }} />
                                <label>High risk zone</label>
                            </div>
                            <div className='zone-legend-item' title="Medium risk zone">
                                <div className='zone-legend-color' style={{ background: 'rgba(230, 157, 115, 0.8)' }} />
                                <label>Medium risk zone</label>
                            </div>
                            <div className='zone-legend-item' title="Low risk zone">
                                <div className='zone-legend-color' style={{ background: 'rgba(136, 177, 139, 0.8)' }} />
                                <label>Low risk zone</label>
                            </div>
                        </div>
                    </div>


                    {selectedTimeline && <>
                        <p>
                            Displays your running risk. The total risk is the theoretical risk of lightning strikes on the
                            selected turbine.
                        </p>
                        <p>
                            The y-axis is a measure of the accumulated risk of lightning strikes within the collection area of the
                            turbine.
                        </p>
                        <p>
                            To lower your 'actual risk', mark turbines as inspected.
                        </p>
                        <p>We call this number <abbr title='Theoretical Lightning Attachment'>TLA</abbr></p>
                    </>}
                </div> : <div className='spinner-area' ><img src={loadingSvg} alt='loading' /></div>}



        </div>
    )
}

