import React, { useContext } from 'react';
import './lightning-page-home.css';
import { LassieContext } from '../../../context/LassieProvider';
import { useNavigate } from "react-router-dom";
import WindfarmList from "../../../components/lassie-specific/WindfarmList";
import loadingSvg from '../../../assets/loading.svg';


export default function LightningHomePage() {
    const navigate = useNavigate();
    const { windfarms, loading } = useContext(LassieContext);

    if (loading) {
        return (
            <div className='lightning-page-home'>
                <div className='bg-img' />
                <div className='overlay loading'>
                    <img src={loadingSvg} alt='loading' />
                </div>
            </div>
        );
    }

    if (windfarms && windfarms.length === 0) {
        return (
            <div className='lightning-page-home'>
                <div className='bg-img' />
                <div className='lightning-popup'>
                    <h2>Windpowerlab's Lightning Monitoring system: LASSIE</h2>
                    <p>Contact us for a demo</p>
                    <button className='popup-btn' onClick={() => navigate('/')} >Back to Wind Diagnostics</button>
                </div>
            </div>
        );
    }

    return (
        <div className='lightning-page-home'>
            <div className='bg-img' />
            <div className='lightning-home-content'>
                <WindfarmList />
            </div>
        </div>
    );
}
