import React, { useEffect } from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const ComposedChartComponent = ({ monthlyData, averageData, maxCount, isDaysChart, selectedYear }) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const calculateYAxisMax = (maxCount) => {
    const roundTo = maxCount > 50 ? 50 : 10;
    return Math.ceil(maxCount / roundTo) * roundTo;
  };

  const formattedMonthlyData = monthlyData[selectedYear] ? monthlyData[selectedYear].map(d => ({
    ...d,
    month: months[d.month - 1],
    monthly_count: isDaysChart ? d.days : d.lightning_count
  })) : [];


  const formattedAverageData = Array.isArray(averageData) ? averageData.map(d => ({
    ...d,
    month: months[d.month - 1],
    average_count: isDaysChart ? d.days.toFixed(2) : d.lightning_count.toFixed(2)
  })) : [];



  const mergedData = formattedMonthlyData.map((monthData, index) => ({
    ...monthData,
    average_count: formattedAverageData[index]?.average_count || 0
  }));

  const yAxisMax = calculateYAxisMax(maxCount);

  return (
    <ResponsiveContainer height={500}>
      <ComposedChart data={mergedData} margin={{ left: 30, bottom: 30 }}>
        <CartesianGrid stroke="#0000001a" vertical={false} />
        <XAxis dataKey="month" />
        <YAxis label={{ value: isDaysChart ? 'Lightning Days' : 'Lightning Strikes', angle: -90, position: 'insideLeft', dx: -25 }} domain={[0, yAxisMax]} />
        <Tooltip />
        <Legend
          align="center"
          verticalAlign="bottom"
          layout="horizontal"
          wrapperStyle={{ paddingTop: 20 }}
        />
        <Bar
          dataKey="monthly_count"
          barSize={40}
          fill="#2e4e63"
          name={isDaysChart ? "Monthly Lightning Days" : "Monthly Lightning Strikes"}
          isAnimationActive={true}
          animationBegin={0}
          animationDuration={1500}
          animationEasing="ease-in-out"
          radius={[5, 5, 0, 0]}
        />
        <Line
          type="monotone"
          dataKey="average_count"
          stroke="#a52a2a"
          dot={true}
          name={isDaysChart ? "Average Lightning Days" : "Average Lightning Strikes"}
          isAnimationActive={true}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ComposedChartComponent;
