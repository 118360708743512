import React, { useState } from 'react';
import Plot from 'react-plotly.js';
import WplInput from "../../../components/wpl-input/WplInput";
import { ExternalVideoProcessingAPI } from '../../../api';
import { heavyExternalVideoProcessingAPI } from '../../../heavy_duty_api';
import loadingSvg from '../../../assets/loading.svg';
import './external-video-processing.css';

export default function ExternalVideoProcessing({ report }) {
    const [bucketPath, setBucketPath] = useState('');
    const [externalVideo, setExternalVideo] = useState(null);
    const [expandedItems, setExpandedItems] = useState({ turbine: null, blade: null });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [chordwise, setChordwise] = useState({ LE: 4, TE: 4, PS: 2, SS: 2 });
    const [plotData, setPlotData] = useState(null);
    const [minDistance, setMinDistance] = useState({ LE: 2, TE: 2, PS: 2, SS: 2 })
    const [maxDistance, setMaxDistance] = useState({ LE: 5, TE: 5, PS: 5, SS: 5 })
    const [overlap, setOverlap] = useState(0.3)
    const [imageData, setImageData] = useState(null)
    const [imageGenPopup, setImageGenPopup] = useState({ isOpen: false, message: '', turbine: '', blade: '' })


    const fetchExternalVideosData = () => {
        if (!bucketPath) return;
        setLoading(true);
        setError('');
        ExternalVideoProcessingAPI.videoOverview(bucketPath)
            .then(data => {
                setExternalVideo(data);
                setLoading(false);
            })
            .catch(() => {
                setError('Failed to fetch external video data');
                setLoading(false);
            });
    };

    const fetchGenerateImages = (turbine, blade) => {
        const getSurfaceData = (surface) => ({
            srt_bucket_path: externalVideo[turbine][blade][surface].srt_bucket_path,
            video_bucket_path: externalVideo[turbine][blade][surface].video_bucket_path,
            max_chord_m: chordwise[surface],
            minimum_distance_to_blade_m: minDistance[surface],
            maximum_distance_to_blade_m: maxDistance[surface]
        });

        const suction_side = getSurfaceData('SS');
        const leading_edge = getSurfaceData('LE');
        const pressure_side = getSurfaceData('PS');
        const trailing_edge = getSurfaceData('TE');

        setImageGenPopup({ isOpen: true, message: "Generating images for turbine: ", turbine: turbine, blade: blade })
        setError('');

        heavyExternalVideoProcessingAPI.generateImages(
            report.id,
            turbine,
            blade,
            overlap,
            suction_side,
            leading_edge,
            pressure_side,
            trailing_edge
        )
            .then(data => {
                setImageGenPopup({ isOpen: true, message: "Images successfully generated for turbine: ", turbine: turbine, blade: blade })
            })
            .catch(() => {
                setError('Failed to generate images');
            });
    };


    const generateFlightPlot = (turbine, blade) => {
        const getSurfaceData = (surface) => ({
            srt_bucket_path: externalVideo[turbine][blade][surface].srt_bucket_path,
            video_bucket_path: externalVideo[turbine][blade][surface].video_bucket_path,
            max_chord_m: chordwise[surface],
            minimum_distance_to_blade_m: minDistance[surface],
            maximum_distance_to_blade_m: maxDistance[surface]
        });

        const suction_side = getSurfaceData('SS');
        const leading_edge = getSurfaceData('LE');
        const pressure_side = getSurfaceData('PS');
        const trailing_edge = getSurfaceData('TE');

        setLoading(true);
        setError('');
        ExternalVideoProcessingAPI.flightPlot(suction_side, leading_edge, pressure_side, trailing_edge)
            .then(data => {
                setPlotData(data);
                setLoading(false);
            })
            .catch(() => {
                setError('Failed to generate flight plot');
                setLoading(false);
            });
    };

    const toggleExpand = (type, item) => {
        setExpandedItems(prevState => ({
            ...prevState,
            [type]: prevState[type] === item ? null : item
        }));
    };

    const handleChordwiseChange = (surface, value) => {
        const newValue = Math.min(Math.max(value, 0), 10);
        setChordwise((prevState) => ({
            ...prevState,
            [surface]: newValue
        }));
    };

    const handleMinDistanceChange = (surface, value) => {
        const newValue = Math.min(Math.max(value, 0), 10);
        setMinDistance((prevState) => ({
            ...prevState,
            [surface]: newValue
        }));
    };

    const handleMaxDistanceChange = (surface, value) => {
        const newValue = Math.min(Math.max(value, 0), 10);
        setMaxDistance((prevState) => ({
            ...prevState,
            [surface]: newValue
        }));
    };

    const handleOverlapChange = (value) => {
        setOverlap(value)
    }


    return (
        <div className='external-video-proc-container'>
            <WplInput
                onChanged={setBucketPath}
                value={bucketPath}
                title='Bucket root'
                multiSelect={false}
            />
            <p className='title-label'>Overlap</p>
            <input
                type="number"
                value={overlap}
                onChange={(e) => handleOverlapChange(e.target.value)}
                min="0"
                max="1"
                step="0.01"
            />


            <div className='external-video-btn-container'>
                <button className="external-video-btn" onClick={fetchExternalVideosData}>Fetch Data</button>
            </div>

            {loading && <div className='overlay-loading'><img src={loadingSvg} alt='loading' /></div>}
            {error && <div className='error-container'><div className="error">{error}</div></div>}
            {imageGenPopup.isOpen && (
                <div className='overlay-loading'>
                    <div className='image-gen-pop-up'>
                        <button className='close-btn' onClick={() => setImageGenPopup({ isOpen: false, turbine: '', blade: '' })}>
                            x
                        </button>
                        <p>{imageGenPopup.message} {imageGenPopup.turbine}, blade: {imageGenPopup.blade}</p>
                    </div>
                </div>
            )}

            {plotData && (
                <div>
                    <Plot
                        data={plotData.data}
                        layout={plotData.layout}
                        config={{ responsive: true }}
                        className='canvas-container'
                    />
                </div>
            )}

            {externalVideo && (
                <div>
                    {Object.keys(externalVideo).map(turbine => (
                        <div key={turbine}>
                            <h3 className='turbine-name' onClick={() => toggleExpand('turbine', turbine)}>Turbine: {turbine}</h3>
                            {expandedItems.turbine === turbine && (
                                <div>
                                    {Object.keys(externalVideo[turbine]).map(blade => (
                                        <div key={blade}>
                                            <h4 className='blade-name' onClick={() => toggleExpand('blade', blade)}>Blade: {blade}</h4>
                                            {expandedItems.blade === blade && (
                                                <div className="surface-table">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Surface</th>
                                                                <th>Image Count</th>
                                                                <th>SRT Path</th>
                                                                <th>Video Path</th>
                                                                <th>Min. Distance(m)</th>
                                                                <th>Max. Distance(m)</th>
                                                                <th>Chordwise Length</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Object.keys(externalVideo[turbine][blade]).map((surface) => (
                                                                <tr key={surface}>
                                                                    <td>{surface}</td>
                                                                    <td>{externalVideo[turbine][blade][surface].image_count}</td>
                                                                    <td>{externalVideo[turbine][blade][surface].srt_bucket_path.split('/').pop()}</td>
                                                                    <td>{externalVideo[turbine][blade][surface].video_bucket_path.split('/').pop()}</td>
                                                                    <td>
                                                                        <input
                                                                            type="number"
                                                                            value={minDistance[surface]}
                                                                            onChange={(e) => handleMinDistanceChange(surface, parseFloat(e.target.value))}
                                                                            min="0"
                                                                            max="10"
                                                                            step="0.1"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="number"
                                                                            value={maxDistance[surface]}
                                                                            onChange={(e) => handleMaxDistanceChange(surface, parseFloat(e.target.value))}
                                                                            min="0"
                                                                            max="10"
                                                                            step="0.1"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="number"
                                                                            value={chordwise[surface]}
                                                                            onChange={(e) => handleChordwiseChange(surface, parseFloat(e.target.value))}
                                                                            min="0"
                                                                            max="10"
                                                                            step="0.1"
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                    <div className='external-video-btn-container'>
                                                        <button className='external-video-btn' onClick={() => generateFlightPlot(expandedItems.turbine, expandedItems.blade)}>Generate flight plot</button>
                                                        <button className='external-video-btn' onClick={() => fetchGenerateImages(expandedItems.turbine, expandedItems.blade)}>Generate image</button>
                                                    </div>
                                                </div>

                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
