import React from 'react'
import "./lightning-cookie-page.css"

export default function LightningCookiePage() {
    return (
        <div className='cookies-container'>
            <h1 className='cookies-title'>Cookies and how they benefit you</h1>

            <p>Our website uses cookies, as almost all websites do, to help provide you with the best experience we can.
                Cookies are small text files that are placed on your computer or mobile phone when you browse websites.</p>
            <p>Our cookies help us:</p>
            <ul>
                <li>Make our website work as you’d expect </li>
                <li>Remember your settings during and between visits</li>
                <li>Improve the speed/security of the site</li>
                <li>Continuously improve our website for you</li>
                <li>Make our marketing more efficient (ultimately helping us to offer the service we do at the price we do)</li>
            </ul>

            <p>We do not use cookies to:</p>
            <ul>
                <li>Collect any personally identifiable information (without your express permission)</li>
                <li>Collect any sensitive information (without your express permission)</li>
                <li>Pass data to advertising networks</li>
                <li>Pass personally identifiable data to third parties</li>
                <li>Pay sales commissions</li>
                <li>You can learn more about all the cookies we use below.</li>
            </ul>

            <h2 className='cookies-subtitle'>Granting us permission to use cookies</h2>
            <p>If the settings on your software that you are using to view this website (your browser) are adjusted to accept cookies we take this, and your continued use of our website, to mean that you are fine with this. Should you wish to remove or not use cookies from our site, doing so will likely mean that our site will not work as you would expect.</p>

            <h2 className='cookies-subtitle'>Website Function Cookies</h2>
            <p>We use cookies to make our website work including:</p>
            <ul>
                <li>Speed up the performance when navigating between wind farms.</li>
                <li>Query new and updated data</li>
            </ul>

            <p>Remembering if we have already asked you certain questions (e.g. you declined to use our app or take our survey)
                There is no way to prevent these cookies being set other than to not use our site.</p>

            <h2 className='cookies-subtitle'>Third Party Cookies</h2>
            <p>Our site, unlike most websites, does not use functionality provided by third parties.</p>
        </div>
    )
}