import axios from 'axios';
import { userService } from "./auth/user-service";

const http = axios.create({
    baseURL: process.env.REACT_APP_SEGMENTATION_URL,
    timeout: 1800000 // 30 minutes
});

http.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    const location = window.location
    const isLoginUrl = location.toString().toLowerCase().indexOf('/login') !== -1

    if (token && !isLoginUrl) {
        const expires = localStorage.getItem('tokenExpires')
        if (expires) {
            if (new Date(expires) < new Date()) {
                userService.logout();
                window.location = '/login';
                throw new axios.Cancel('Token expired. Moving to login.');
            }
        }
        config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
});

const easyUnpack = ({ data }) => data;

const easyCatch = (err) => {
    if (err.err) {
        console.error(err)
    } else if (err.response && err.response.status === 401) {
        userService.logout()
        window.location = '/login'
    }
    throw err;
};

export const heavySegmentationAPI = {
    unetPredictBackground(picture_id) {
        return http.post('/segmentation/unet-predict', {
            picture_id
        })
    },
    unetPredictMultipleBackground(picture_ids) {
        return http.post('/segmentation/unet-predict-multi', {
            picture_ids
        }).then(easyUnpack)
    },
    generateRfModel(selectedRfModel) {
        return http.post('/segmentation/generate-rf-model', selectedRfModel).then(easyUnpack)
    }
};

export const heavyInternalVideoProcessingAPI = {
    stitchMovie(inspection_values) {
        return http
            .post(`internal-video/processing/stitch`, {
                inspection_values
            })
            .then(easyUnpack)
    },
    generateStabilization(inspection_values) {
        return http
            .post(`internal-video/processing/stabilize`, {
                inspection_values
            })
            .then(easyUnpack)
    },
};

export const heavyExternalVideoProcessingAPI = {
    generateImages(report_id, turbine, blade, overlap, suction_side, leading_edge, pressure_side, trailing_edge) {
        return http
            .post('/external-video/processing/generate-images', {
                report_id, turbine, blade, overlap, suction_side, leading_edge, pressure_side, trailing_edge
            })
            .then(easyUnpack)
    }
}

export const heavyDatamanagementAPI = {
    parseReport(report_id) {
        return http
            .get(`/data_management/parse_goose_report/${report_id}`)
            .then(easyUnpack)
    }
};

export const heavyLassieRegionApi = {
    listRegions(region_ids, limit_history_days) {
        return http
            .get(`/lassie/region`, { params: { region_ids, limit_history_days } })
            .then(easyUnpack)
    },
};
