import React from "react"
import './weather-sentry-page.css'

export default function WeatherSentryPage() {
    return (
        <div className="weather-sentry-container">
                <iframe
                    src="https://weather.dtn.com/dtnweather/"
                    title="DTN Weather Sentry: Real-time weather updates"
                    frameBorder="0"
                    style={{height:"100%", width:"100%", boxShadow: "rgba(0, 0, 0, 0.09) 0 4px 24px", borderRadius:"8px" }}
                    allowFullScreen
                    loading="lazy"
                ></iframe>
        </div>
    )
}
