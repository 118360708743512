import React, { createContext, useState, useRef, useEffect } from "react";
import { lightningAPI, lightningScenarioAPI, lassieDashboardAPI, lightningUserActionAPI } from '../api'

export const LassieContext = createContext();


export default function LassieProvider({ children }) {
    const oneHour = 60 * 60 * 1000;
    const [windfarms, setWindfarms] = useState([])
    const [selectedWindfarm, setSelectedWindfarm] = useState(null);
    const [wfStatistics, setWfStatistics] = useState([]);
    const [turbines, setTurbines] = useState([])
    const [turbinesStatus, setTurbinesStatus] = useState({ noRisk: [], atRisk: [], toBeInspected: [] })
    const [loading, setLoading] = useState(true);
    const [scenarios, setScenarios] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [historicActivity, setHistoricActivity] = useState([]);
    const [historicUserAction, setHistoricUserAction] = useState([]);
    const [historicTriggers, setHistoricTriggers] = useState([]);
    const [scenarioStatistics, setScenarioStatistics] = useState(null);
    const [responseStatistics, setResponseStatistics] = useState(null);
    const [windfarmStatistics, setWindfarmStatistics] = useState(null);
    const [seasonalStatistics, setSeasonalStatistics] = useState([]);
    const [turbineStatistics, setTurbineStatistics] = useState([]);



    const logError = (message, error) => {
        console.error(message, error);
    };

    const sortTurbines = (turbines) => {
        if (!turbines) return
        return turbines.sort((a, b) => b.probability_of_atleast_one - a.probability_of_atleast_one)
    }


    const fetchWindfarms = async () => {
        const storedWindfarms = JSON.parse(localStorage.getItem('windfarms'));
        const lastFetched = localStorage.getItem('windfarmsTimestamp');

        if (storedWindfarms && Date.now() - lastFetched < oneHour) {
            setWindfarms(storedWindfarms);
            setLoading(false);
        } else {
            try {
                const data = await lightningAPI.listLightningWindfarms();
                setWindfarms(data);
                localStorage.setItem('windfarms', JSON.stringify(data));
                localStorage.setItem('windfarmsTimestamp', Date.now());
            } catch (error) {
                console.error('Error fetching windfarm data', error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        const handleLoad = () => {
            localStorage.removeItem('windfarms');
            localStorage.removeItem('windfarmsTimestamp');
        };

        window.addEventListener('load', handleLoad);
        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, []);

    const fetchStatistics = async (windfarm_id) => {
        try {
            const stats = await lightningAPI.getStatistics({ windfarm_id });
            setWfStatistics(stats);
        } catch (error) {
            logError(`Error fetching statistics for windfarm ${windfarm_id}`, error);
        }
    };

    const fetchTurbines = async (windfarm_id) => {
        try {
            const allTurbines = await lightningAPI.listAllLightningTurbines(windfarm_id);
            const sortedTurbines = sortTurbines(allTurbines);

            const filterTurbinesByStatus = (status) => sortedTurbines.filter(t => t.status === status);

            setTurbines(sortedTurbines);
            setTurbinesStatus({
                noRisk: filterTurbinesByStatus('acceptable_risk'),
                atRisk: filterTurbinesByStatus('at_risk'),
                toBeInspected: filterTurbinesByStatus('marked_for_inspection')
            });
        } catch (error) {
            logError(`Error fetching turbines for windfarm ${windfarm_id}`, error);
        }
    };

    const fetchScenarios = async (windfarm_id) => {
        try {
            const scenariosData = await lightningScenarioAPI.list_scenarios_by_windfarm_id(windfarm_id);
            setScenarios(scenariosData);
        } catch (error) {
            logError(`Error fetching scenarios for windfarm ${windfarm_id}`, error);
        }
    };

    const fetchNotifications = async (windfarm_id) => {
        try {
            const notificationsData = await lassieDashboardAPI.listNotificationOverview(windfarm_id);
            setNotifications(notificationsData);
        } catch (error) {
            logError(`Error fetching notifications for windfarm ${windfarm_id}`, error);
        }
    };

    const fetchHistoricActivity = async (windfarm_id, from_timestamp, to_timestamp) => {
        try {
            const historicActivityData = await lassieDashboardAPI.listHistoricActivity({ windfarm_id, from_timestamp, to_timestamp });
            setHistoricActivity(historicActivityData)
        } catch (error) {
            logError(`Error fetching historic activity for windfarm ${windfarm_id}`, error);


        }
    }

    const fetchHistoricUserAction = async (windfarm_id, from_timestamp, to_timestamp) => {
        try {
            const historicUserActionsData = await lassieDashboardAPI.listWindfarmsHistoricUserAction(windfarm_id, from_timestamp, to_timestamp);
            setHistoricUserAction(historicUserActionsData)
        } catch (error) {
            logError(`Error fetching historic user actions for windfarm ${windfarm_id}`, error);



        }
    }

    const fetchHistoricTriggers = async (windfarm_id, from_timestamp, to_timestamp) => {
        try {
            const historicTriggersData = await lassieDashboardAPI.listHistoricTriggers(windfarm_id, from_timestamp, to_timestamp)
            setHistoricTriggers(historicTriggersData)
        } catch (error) {
            logError(`Error fetching historic triggers for windfarm ${windfarm_id}`, error);

        }
    }

    const createUserAction = async ({
        turbine_ids,
        action,
        user_comment,
        target_date,
        file_names,
        file_datas,
    }) => {
        try {
            const updatedTurbines = await lightningUserActionAPI.insert_user_action(
                turbine_ids,
                action,
                user_comment,
                target_date,
                file_names,
                file_datas
            );

            setTurbines((prevTurbines) =>
                prevTurbines.map((turbine) =>
                    updatedTurbines.some((updated) => updated.id === turbine.id)
                        ? updatedTurbines.find((updated) => updated.id === turbine.id)
                        : turbine
                )
            );
        } catch (error) {
            console.error('Error creating user action:', error);
        }
    };

    const fetchStatisticsData = async (startDate, endDate) => {
        try {
            const [scenarioData, responseData, windfarmData] = await Promise.all([
                lassieDashboardAPI.listScenarioStatistics(),
                lassieDashboardAPI.listResponseStatistics(startDate, endDate),
                lassieDashboardAPI.listStatistics(startDate, endDate)
            ]);

            setScenarioStatistics(scenarioData);
            setResponseStatistics(responseData);
            setWindfarmStatistics(windfarmData);
        } catch (error) {
            console.error('Error fetching statistics data:', error);
            throw error;
        }
    };

    const fetchSeasonalStatistics = async (windfarm_id) => {
        try {
            const seasonalData = await lassieDashboardAPI.listSeasonalStatistics(windfarm_id);
            return seasonalData;
        } catch (error) {
            console.error('Error fetching seasonal data:', error);
            throw error;
        }
    };

    const fetchTurbineStatistics = async (startDate, endDate, windfarm_id) => {
        try {
            const data = await lassieDashboardAPI.listTurbineStatistics(startDate, endDate, windfarm_id);
            return data;
        } catch (error) {
            console.error('Error fetching turbine statistics:', error);
            throw error;
        }
    };


    useEffect(() => {
        fetchWindfarms();
        setTurbines([])

        const intervalId = setInterval(fetchWindfarms, oneHour);
        return () => clearInterval(intervalId);
    }, [selectedWindfarm]);


    return (
        <LassieContext.Provider
            value={{
                windfarms,
                setWindfarms,
                selectedWindfarm,
                setSelectedWindfarm,
                loading,
                setLoading,
                wfStatistics,
                setWfStatistics,
                fetchStatistics,
                fetchTurbines,
                turbines,
                turbinesStatus,
                fetchScenarios,
                scenarios,
                setScenarios,
                fetchNotifications,
                notifications,
                setNotifications,
                fetchHistoricActivity,
                historicActivity,
                setHistoricActivity,
                fetchHistoricUserAction,
                historicUserAction,
                setHistoricUserAction,
                fetchHistoricTriggers,
                historicTriggers,
                setHistoricTriggers,
                createUserAction,
                fetchStatisticsData,
                scenarioStatistics,
                setScenarioStatistics,
                responseStatistics,
                setResponseStatistics,
                windfarmStatistics,
                setWindfarmStatistics,
                fetchSeasonalStatistics,
                seasonalStatistics,
                setSeasonalStatistics,
                fetchTurbineStatistics,
                turbineStatistics,
                setTurbineStatistics,
            }}
        >
            {children}
        </LassieContext.Provider>
    )
}