import React, { useState, useMemo, useCallback } from 'react'
import './lightning-table.css'

export default function LightningTable({
    loading,
    items,
    fields,
    className,
    customSortFunction,
    customRenderFunction = null,
    isFetched
}) {
    const [sortByColumn, setSortByColumn] = useState(fields[0]?.key || '');
    const [ascDesc, setAscDesc] = useState(1);

    const sortedItems = useMemo(() => {
        const defaultSort = (a, b) => {
            if (a[sortByColumn] === b[sortByColumn]) return 0;
            if (a[sortByColumn] === null) return -1 * ascDesc;
            if (b[sortByColumn] === null) return ascDesc;

            // if text, sort alphabetically
            if (typeof a[sortByColumn] === 'string')
                return a[sortByColumn].localeCompare(b[sortByColumn]) * ascDesc

            if (a[sortByColumn] > b[sortByColumn]) {
                return -1 * ascDesc
            }
            if (a[sortByColumn] < b[sortByColumn]) {
                return ascDesc
            }

            return 0
        };

        const sortFunction = customSortFunction || defaultSort;
        return [...items].sort(sortFunction);
    }, [items, customSortFunction, sortByColumn, ascDesc]);

    const clickedSort = useCallback((headerName) => {
        if (headerName === sortByColumn) {
            if (ascDesc === -1) {
                setSortByColumn(null);
                setAscDesc(1);
            } else {
                setAscDesc(-ascDesc);
            }
        } else {
            setSortByColumn(headerName);
            setAscDesc(1);
        }
    }, [sortByColumn, ascDesc]);

    return (<div className={`lightning-table ${className}`}>
        <table>
            <thead>
                <tr>
                    {fields.map(({ key, title }) => (<th key={key} onClick={() => clickedSort(key)}>
                        {title}
                        <svg
                            className={`arrow ${(sortByColumn === key) ? 'focus' : ''} ${ascDesc === -1 ? "asc" : "desc"}`}
                            version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                            viewBox="0 0 330 330">
                            <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                        c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                        s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.394z"/>
                        </svg>
                    </th>))}
                </tr>
            </thead>
            <tbody>
                {sortedItems.map((item, idx) => {
                    if (customRenderFunction) return customRenderFunction(item, idx);

                    return (<tr key={item.id || idx}>
                        {fields.map(({ key }) => <td key={key}>{item[key]}</td>)}
                    </tr>)
                }
                )}
                {isFetched && !sortedItems.length && !loading && <tr>
                    <td colSpan={fields.length} style={{ textAlign: 'center' }}>
                        No data matches your current selection
                    </td>
                </tr>}
            </tbody>
        </table>
    </div>);
}
