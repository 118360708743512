import React from 'react'
import { Link } from 'react-router-dom';
import './footer.css'

export default function (props) {
    return (<div className='footer'>
            <p>© 2024 All Rights Reserved.</p>
        <div className='footer-links'>
            {/* <Link to="/lassie/terms">Terms</Link> */}
            <Link to="/lassie/cookie-policy">Cookie Policy</Link>
            <Link to="/lassie/privacy-policy">Privacy Policy</Link>
        </div>
    </div>)
}
