import React, { useEffect, useMemo, useState, useContext } from 'react';
import './dashboard.css';
import DashboardCard from './DashboardCard';
import { LassieContext } from '../../../context/LassieProvider';
import { Link, useNavigate, useParams } from "react-router-dom";
import { toPrettyDateStr } from "../../../prettyDate";
import ComposedChartComponent from './ComposedChartComponent';
import { extractUniqueYears, processDataForCharts } from './utils';
import windfarmIcon from '../../../assets/img/windfarm.png';
import WplButton from '../../../components/wpl-button/WplButton';
import PieChartComponent from './PieChartComponent';
import WplInput from '../../../components/wpl-input/WplInput';
import loadingSvg from '../../../assets/loading.svg';


export default function DashboardPage() {
    const navigate = useNavigate();
    const { fetchStatisticsData,
        scenarioStatistics,
        responseStatistics,
        windfarmStatistics,
        seasonalStatistics,
        setSeasonalStatistics,
        fetchSeasonalStatistics,
        fetchTurbineStatistics,
        turbineStatistics,
        setTurbineStatistics } = useContext(LassieContext);

    const [seasonalStatisticsYears, setSeasonalStatisticsYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [loading, setLoading] = useState(false);
    const [loadingSeasonal, setLoadingSeasonal] = useState(false);
    const [loadingTurbines, setLoadingTurbines] = useState(false);


    const [showDetailedActivity, setShowDetailedActivity] = useState(false);
    const [isDaysChart, setIsDaysChart] = useState(false)

    let { windfarm_id: wf_id } = useParams();

    const windfarm_id = useMemo(() => {
        if (wf_id) {
            return parseInt(wf_id);
        }
        return null;
    }, [wf_id]);

    const selectedWfStats = useMemo(() => {
        if (!windfarm_id || !windfarmStatistics || windfarmStatistics.length === 0) return null;
        return windfarmStatistics.find(s => s.id === windfarm_id);
    }, [windfarm_id, windfarmStatistics]);

    const dates = useMemo(() => {
        let endDate = new Date();
        let startDate = new Date(new Date().setDate(endDate.getDate() - 365));

        return {
            start: toPrettyDateStr(startDate),
            end: toPrettyDateStr(endDate),
        };
    }, []);


    const {
        monthlyLightningData,
        monthlyDayData,
        averageLightningData,
        averageDayData,
        maxLightningCount,
        maxDaysCount
    } = useMemo(() => processDataForCharts(seasonalStatistics, selectedYear), [seasonalStatistics, selectedYear]);

    useEffect(() => {
        if (!dates) return;

        const fetchData = async () => {
            setLoading(true);
            try {
                await fetchStatisticsData(dates.start, dates.end);
            } catch (err) {
                console.log('Error fetching statistcs:', err)
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        if (!windfarm_id) return;

        const fetchSeasonalData = async () => {
            setLoadingSeasonal(true);
            try {
                const seasonalData = await fetchSeasonalStatistics(windfarm_id);
                setSeasonalStatistics(seasonalData);
            } catch (err) {
                console.log('Error fetching seasonal data:', err);
            } finally {
                setLoadingSeasonal(false);
            }
        };

        fetchSeasonalData();
    }, [windfarm_id]);

    useEffect(() => {
        if (!dates) return;

        const fetchTurbineData = async () => {
            setLoadingTurbines(true);
            try {
                const data = await fetchTurbineStatistics(dates.start, dates.end, windfarm_id);
                setTurbineStatistics(data);
            } catch (err) {
                console.error('Error fetching turbine statistics:', err);
            } finally {
                setLoadingTurbines(false);
            }
        };

        fetchTurbineData();
    }, [windfarm_id, dates]);

    useEffect(() => {
        if (!windfarm_id) return;

        if (seasonalStatistics.length > 0) {
            const uniqueYears = extractUniqueYears(seasonalStatistics);
            setSeasonalStatisticsYears(uniqueYears);
            if (!loadingSeasonal) {
                setSeasonalStatisticsYears(uniqueYears);
                setSelectedYear(Math.max(...uniqueYears));
            }
        }
    }, [seasonalStatistics, windfarm_id]);

    const handleYearChange = (event) => {
        setSelectedYear(parseInt(event.target.value));
    };



    const indicators = useMemo(() => {
        if (!windfarmStatistics || windfarmStatistics.length === 0) return [];

        let totalStrikes = 0;
        let outsideIec = 0;
        let mostActiveWindfarm = windfarmStatistics.sort((a, b) => b.total - a.total)[0].name;
        windfarmStatistics.forEach(s => {
            totalStrikes += s.total;
            outsideIec += s.outside_iec;
        });

        return [
            {
                title: 'Wind farm with the highest lightning activity.',
                value: mostActiveWindfarm,
                image: windfarmIcon
            },
            {
                title: 'Total Strikes',
                value: totalStrikes,
                tooltip: 'Within 500 meters per turbine',
                cardClusterId: 2
            },
            {
                title: 'Strikes outside IEC 2010',
                value: outsideIec,
                cardClusterId: 2
            }
        ];
    }, [windfarmStatistics]);

    const responseIndicators = useMemo(() => {
        if (!responseStatistics) return [];

        let filteredResponseStatistics = responseStatistics;

        if (windfarm_id) {
            filteredResponseStatistics = responseStatistics.filter(r => r.windfarm_id === windfarm_id);
        }

        const marked_for = filteredResponseStatistics.reduce((p, c) => p + (c.action === 'marked_for_inspection'), 0);
        const inspections = filteredResponseStatistics.reduce((p, c) => p + (c.action === 'inspection'), 0);

        return [
            {
                title: 'Marked for Inspection',
                value: marked_for
            },
            {
                title: 'Inspections',
                value: inspections
            }
        ];
    }, [responseStatistics, windfarm_id]);

    const responseByWindfarm = useMemo(() => {
        if (!responseStatistics) return [];

        const byWf = responseStatistics.reduce((res, curr) => {
            const name = curr.windfarm_name;

            const inspected = curr.action === 'inspection';
            const marked = curr.action === 'marked_for_inspection';

            if (!(name in res)) {
                res[name] = { inspection: 0, marked_for_inspection: 0 };
            }
            res[name].inspection += inspected;
            res[name].marked_for_inspection += marked;

            return res;
        }, {});

        return Object.keys(byWf).map(windfarm_name => ({ windfarm_name, ...byWf[windfarm_name] }));
    }, [responseStatistics]);

    const avgStrikeWithin500m = useMemo(() => {
        if (!windfarmStatistics) return {};
        const res = {};
        windfarmStatistics.forEach(s => res[s.id] = Math.round(s['500m'] / s.turbine_count * 10) / 10);
        return res;
    }, [windfarmStatistics]);

    const handleWindfarmChange = (name) => {
        if (name === 'All windfarms') {
            navigate('/lassie/dashboard');
        } else {
            const wf = windfarmStatistics.find(w => w.name === name);
            if (wf) {
                navigate(`/lassie/dashboard/windfarm/${wf.id}`);
            }
        }
    };

    return (
        <div className='lightning-dashboard'>
            <div className='main-dashboard'>
                <h1>Dashboard</h1>

                <div className="dashboard-select">
                    {windfarmStatistics && <WplInput
                        title="Select a windfarm"
                        className="no-risk-windfarms"
                        options={['All windfarms', ...windfarmStatistics.map(wf => wf.name)]}
                        onlySelectableOptions
                        value={selectedWfStats ? selectedWfStats.name : 'All windfarms'}
                        placeholder="Select a windfarm..."
                        onChanged={handleWindfarmChange}
                    />}
                </div>

                {!loading && !loadingSeasonal && !loadingTurbines ?
                    <>
                        <h2 className='period-title'>{selectedWfStats && selectedWfStats.name}</h2>

                        {!windfarm_id && indicators && !loading && <DashboardCard statistics={indicators} />}

                        {selectedWfStats && !loading && <div>
                            <DashboardCard statistics={[
                                {
                                    title: 'Turbines installed',
                                    value: selectedWfStats.turbine_count,
                                    image: windfarmIcon
                                },
                                {
                                    title: 'Total Strikes',
                                    value: selectedWfStats.total
                                },
                                {
                                    title: 'No. outside IEC 2010',
                                    value: selectedWfStats.outside_iec,
                                },
                                {
                                    title: 'Average strikes',
                                    value: Math.round(selectedWfStats['500m'] / selectedWfStats.turbine_count * 10) / 10,
                                    tooltip: 'Within 500 meters per turbine',
                                },
                            ]} />
                        </div>}

                        {seasonalStatistics.length > 0 && windfarm_id && !loading &&
                            <div className='seasonal-statistics-area'>
                                <h4>Seasonal Statistics</h4>
                                <div className='from-to-date-wrapper'>

                                    <WplButton className={`year-option ${!isDaysChart ? 'selected' : ''}`} value='Lightning strikes' onClick={() => setIsDaysChart(false)} />
                                    <WplButton className={`year-option ${isDaysChart ? 'selected' : ''}`} value='Lightning days' onClick={() => setIsDaysChart(true)} />

                                </div>
                                <div className='from-to-date-wrapper'>
                                    {seasonalStatisticsYears.map(year => {
                                        return (
                                            <WplButton
                                                className={`year-option ${selectedYear === year ? 'selected' : ''}`}
                                                value={year}
                                                key={year}
                                                onClick={handleYearChange}
                                            />
                                        );
                                    })}
                                </div>
                                <div className='seasonal-statistics-wrapper'>
                                    {isDaysChart ?
                                        <ComposedChartComponent
                                            monthlyData={monthlyDayData}
                                            averageData={averageDayData}
                                            maxCount={maxDaysCount}
                                            isDaysChart={isDaysChart}
                                            selectedYear={selectedYear}
                                        />
                                        :
                                        <ComposedChartComponent
                                            monthlyData={monthlyLightningData}
                                            averageData={averageLightningData}
                                            maxCount={maxLightningCount}
                                            isDaysChart={isDaysChart}
                                            selectedYear={selectedYear}
                                        />

                                    }
                                </div>
                            </div>
                        }

                        {turbineStatistics && windfarm_id && <div>
                            <h4>Theoretical risk overview</h4>
                            <DashboardCard statistics={[
                                {
                                    title: 'Theoretical expected attachments',
                                    value: Math.round(turbineStatistics.reduce((p, c) => p + c.expected_value, 0) * 10) / 10,
                                }, {
                                    title: 'Turbines with no lightning activity',
                                    value: turbineStatistics.filter(ts => ts.expected_value <= 1).length,
                                }, {
                                    title: 'Turbines with more than 1 strike',
                                    value: turbineStatistics.filter(ts => ts.expected_value > 1).length,
                                },
                            ]} />
                        </div>
                        }

                        {!windfarm_id && windfarmStatistics && (
                            <>
                                <h4>Average lightning activity</h4>
                                <table className="dashboard-table chart-table">
                                    <thead>
                                        <tr>
                                            <th className='dashboard-th'>Windfarm</th>
                                            <th className='dashboard-th' >Turbines</th>
                                            <th className="chart-header">
                                                Average strikes per turbine within 500 meters
                                                <div className="chart-reference">
                                                    <span className="chart-reference-number">0</span>
                                                    <span className="chart-reference-number">
                                                        {Math.ceil(Math.max(...Object.values(avgStrikeWithin500m)))}
                                                    </span>
                                                </div>
                                                <div className="chart-reference-line"></div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {windfarmStatistics.map((s) => (
                                            <tr key={s.id}>
                                                <td className="windfarm-link dashboard-td">
                                                    <Link to={`/lassie/dashboard/windfarm/${s.id}`}>{s.name}</Link>
                                                </td>
                                                <td className='dashboard-td'>{s.turbine_count}</td>
                                                <td className='dashboard-td'>
                                                    <div className='bar-container'>

                                                        <div
                                                            style={{
                                                                width: `${(avgStrikeWithin500m[s.id] /
                                                                    Math.max(...Object.values(avgStrikeWithin500m))) *
                                                                    100
                                                                    }%`,
                                                            }}
                                                            className="bar-chart-column"
                                                        >
                                                            {avgStrikeWithin500m[s.id]}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        )}

                        {turbineStatistics && !loading && (
                            <>
                                <h4>Turbines with most lightning activity</h4>
                                <table className={showDetailedActivity ? 'dashboard-table table-custom-width' : 'dashboard-table'}>
                                    <thead>
                                        <tr>
                                            {!windfarm_id && <th>Windfarm</th>}
                                            <th className='dashboard-th'>Turbine</th>
                                            <th className='dashboard-th'>{'Theoretical Attachment Count'}</th>
                                            {showDetailedActivity && <th className='dashboard-th'>{'<500m'}</th>}
                                            {showDetailedActivity && <th className='dashboard-th'>{'<200m'}</th>}
                                            {showDetailedActivity && <th className='dashboard-th'>{'<100m'}</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {turbineStatistics.filter((_, idx) => idx < 8).map((s, i) => <tr key={i}>
                                            {!windfarm_id && <td className='dashboard-td'>{s.windfarm_name}</td>}
                                            <td className='dashboard-td'>{s.turbine_name}</td>
                                            <td className='dashboard-td'>{Math.round(s.expected_value * 10) / 10}</td>
                                            {showDetailedActivity && <td className='dashboard-td'>{s['500m']}</td>}
                                            {showDetailedActivity && <td className='dashboard-td'>{s['200m']}</td>}
                                            {showDetailedActivity && <td className='dashboard-td'>{s['100m']}</td>}
                                        </tr>)}
                                    </tbody>
                                    <tbody className='button-container'>
                                        <tr className={`arrow-icon ${showDetailedActivity ? 'open' : 'closed'}`} onClick={() => setShowDetailedActivity(!showDetailedActivity)}></tr>
                                    </tbody>
                                </table>

                            </>
                        )}

                        {windfarm_id && scenarioStatistics &&
                            <>
                                <h4>Notifications overview</h4>
                                <div className='pie-area'>
                                    {scenarioStatistics.filter(ss => ss.windfarm_id === windfarm_id).map((ss, i) =>
                                        <div key={i} className='pie-wrapper'>
                                            <h4 className='pie-title'>{ss.trigger_title}</h4>
                                            <p className='pie-data'>{Math.round((ss.handled / ss.events) * 100)}% handled</p>
                                            <PieChartComponent
                                                data={[{ text: 'Triggers', value: 1 - (ss.handled / ss.events) }, {
                                                    text: 'Handled',
                                                    value: ss.handled / ss.events
                                                }]}
                                                innerRadius={50}
                                                outerRadius={80}
                                                colors={[
                                                    '#a52a2a',
                                                    '#7cbb42',
                                                ]} />
                                        </div>)}
                                </div>
                            </>
                        }
                    </> : <div className='overlay loading'><img src={loadingSvg} alt='loading' /></div>
                }

            </div>
        </div>
    )
}
