import React, { useEffect, useMemo, useState, useContext } from 'react';
import { LassieContext } from '../../../context/LassieProvider';
import { useNavigate, useParams } from "react-router-dom";
import { toPrettyTimeSince } from "../../../prettyDate";
import { lightningAPI } from "../../../api";
import ListOfTurbines from "../list-of-turbines/ListOfTurbines";
import ManageLightningRisk from "../manage-lightning-risk/ManageLightningRisk";
import ManageLightningInspection from "../manage-lightning-inspection/ManageLightningInspection";
import InfoCards from "../../../components/info-card/InfoCard";
import Card from "../../../components/card/Card";
import WplButton from "../../../components/wpl-button/WplButton";
import CenteredPopup from "../../../components/centered-popup/CenteredPopup";
import LightningScenarioSettings from "../../lightning-settings/lightning-scenario-settings/LightningScenarioSettings";
import OlMap from "../../../components/ol-map/OlMap";
import RiskTimelineGraph from './RiskTimelineGraph';
import './lightning-windfarm-page.css'
import { useSyncSelectedWindfarm } from '../../../hooks/useSyncSelectedWindfarm '

export default function LightningWindfarmPage() {
    const [showScenarioSettings, setShowScenarioSettings] = useState(false);
    const [riskTimeline, setRiskTimeline] = useState(null);
    const [showRiskGraph, setShowRiskGraph] = useState(false)
    const { windfarms, wfStatistics, fetchStatistics, fetchTurbines, turbines, turbinesStatus } = useContext(LassieContext)

    const navigate = useNavigate();

    let { windfarm_id } = useParams();
    if (windfarm_id) {
        windfarm_id = parseInt(windfarm_id)
    }

    const windfarm = useMemo(() => {
        if (!windfarms || !windfarm_id) return null;
        return windfarms.find(wf => wf.id === parseInt(windfarm_id));
    }, [windfarms, windfarm_id]);


    useEffect(() => {
        if (!windfarm_id) return
        fetchStatistics(windfarm_id);
        fetchTurbines(windfarm_id)

    }, [windfarm_id]);

    const callRiskData = () => {
        setShowRiskGraph(!showRiskGraph)
        lightningAPI.listWindfarmRisktimeline(windfarm_id).then(setRiskTimeline)
    }

    useSyncSelectedWindfarm(windfarm_id);

    return (<div className='lightning-windfarm-page'>
        <CenteredPopup showPopup={showScenarioSettings} closePopup={setShowScenarioSettings}>
            <LightningScenarioSettings can_edit={false} specific_windfarm_id={parseInt(windfarm_id)} />
        </CenteredPopup>
        <h1 style={{ marginBottom: 0 }}>{windfarm && windfarm.name}</h1>


        {windfarm && <p style={{ color: 'dimgrey', marginTop: 0, marginBottom: 20 }}>Latest data
            acquisition: {toPrettyTimeSince(new Date(windfarm.latest_data))}</p>}

        {wfStatistics.length > 0 && turbines.length > 0 && (
            <>

                <OlMap
                    key='main-map'
                    lightning_strikes={[...turbines.map(t => t.turbine_lightning)].flat()}
                    turbines={turbines}
                />

                <div className='statistics-container'>
                    <h3>Lightning activity</h3>
                    <InfoCards statistics={wfStatistics} />
                </div>


                <div className='list-wrapper'>
                    <ListOfTurbines turbines={turbinesStatus.atRisk}
                        title={'Turbines at risk'}
                        PopupComponent={ManageLightningRisk}
                        buttonText={'Manage risk'} />
                    <div className='spacer' />
                    <ListOfTurbines turbines={turbinesStatus.toBeInspected}
                        title={'Marked for inspection'}
                        PopupComponent={ManageLightningInspection}
                        buttonText={'Manage pending turbines'}
                        hideRisk={true}
                    />
                    <div className='spacer' />
                    <ListOfTurbines turbines={turbinesStatus.noRisk}
                        title={'Managed turbines'}
                        PopupComponent={ManageLightningRisk}
                        buttonText={'Details'}
                        hideRisk={true}
                    />
                </div>
                <WplButton className='complete-turbine-list-btn' value='Complete Turbine List'
                    onClick={_ => navigate('complete-turbine-list')} />
                <Card className={`risk-graph-area ${showRiskGraph ? 'open' : 'closed'}`} >
                    <div className='risk-graph-header'>
                        <h1>Your risk timeline</h1>

                        <div
                            onClick={callRiskData}
                            className={`risk-timeline-arrow-icon ${showRiskGraph ? 'open' : 'closed'}`}
                        ></div>

                    </div>
                    {showRiskGraph &&
                        <RiskTimelineGraph riskTimeline={riskTimeline} />
                    }
                </Card>
            </>
        )}


    </div>)
}
