import React from 'react'
import './lightning-terms-page.css'

export default function LightningTermsPage ()  {
  return (
    <div>
      <h1>Terms & conditions</h1>
    </div>
  )
}

