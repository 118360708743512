import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { LassieContext } from '../../context/LassieProvider';
import './ol-map.css'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import XYZ from 'ol/source/XYZ'
import { Point, Polygon } from "ol/geom";
import { Feature } from "ol";
import { fromLonLat } from "ol/proj";
import { Fill, Stroke, Style } from "ol/style";
import { Heatmap } from "ol/layer";
import TurbineLightningRuler from "../turbine-lightning-ruler/TurbineLightningRuler";
import WplButton from "../wpl-button/WplButton";
import { toPrettyDateStr } from "../../prettyDate";
import WplPopup from "../wpl-popup-window/WplPopup";
import { createUserAction } from "../../flow/lightningFlow";
import { useDispatch } from "react-redux";
import { getIconForTurbine } from "./icons";
import createSourceFromFeatures from "./createEllipseSourceFromFeatures";
import useOlMap from "./useOlMap";
import MapSettings from '../map-settings/MapSettings';
import MapReferences from '../map-references/MapReferences';
import MapQa from '../map-qa/MapQa';

const tileLayerFromUrl = (url) => new TileLayer({
    source: new XYZ({
        url,
    })
})

export default function OlMap({ turbines, lightning_strikes, forceShiftkeyToZoom = false }) {

    const { createUserAction } = useContext(LassieContext)

    const [popoutSettingsOpen, setPopoutSettingsOpen] = useState(false);
    const [popoutReferencesOpen, setPopoutReferencesOpen] = useState(false);
    const [qaOpen, setQaOpen] = useState({ status: false, type: '' });
    const [showLightningTable, setShowLightningTable] = useState(true);
    const [showSensorTable, setShowSensorTable] = useState(false);

    const [layers, setLayers] = useState({});

    const [selectedTurbine, setSelectedTurbine] = useState(null);
    const [layerSettings, setLayerSettings] = useState({
        background: 'topographic',
        turbines: true,
        lightning: false,
        collection_area: false,
        heatmap: false,
        turbine_specific: [],
        most_probable: false,
        only_show_outside_iec: false
    });

    const {
        initialized,
        map,
        mapElement,
        setInitialSourceRender,
        turbineStyling
    } = useOlMap();

    // turbine selection handling
    const [highlighted, setHighlighted] = useState(null);
    const [hovering, setHovering] = useState(null);

    // display 'hold shift to zoom' popup
    const [showHoldShiftToZoomPopup, setShowHoldShiftToZoomPopup] = useState(false);

    useEffect(() => {
        if (showHoldShiftToZoomPopup) {
            setTimeout(() => {
                setShowHoldShiftToZoomPopup(false);
            }, 5000);
        }
    }, [showHoldShiftToZoomPopup]);

    const handleOnPointerMove = useCallback(e => {
        let newHover = null;
        map.forEachFeatureAtPixel(e.pixel, function (f) {
            if (!f.get('turbine_id')) return;
            if (newHover) return;
            newHover = f;
        });
        if (newHover === hovering) return;

        setHovering(previousHover => {
            if (newHover) {
                if (!selectedTurbine || (newHover.get('turbine_id') !== selectedTurbine.id)) {
                    newHover.setStyle(turbineStyling(newHover, map).hover);
                }
            }
            if (previousHover) {
                if (!selectedTurbine || (previousHover.get('turbine_id') !== selectedTurbine.id)) {
                    previousHover.setStyle(turbineStyling(previousHover, map).normal);
                }
            }
            return newHover
        });
    }, [hovering, turbineStyling, selectedTurbine, map]);


    const handleOnClick = useCallback(e => {
        setPopoutSettingsOpen(false);
        setPopoutReferencesOpen(false);
        if (highlighted !== null) {
            highlighted.setStyle(turbineStyling(highlighted, map).normal);
            setHighlighted(null);
        }

        let newlySelected = null;
        map.forEachFeatureAtPixel(e.pixel, function (f) {
            if (highlighted === f) return;

            const turbineId = f.get('turbine_id')
            if (!turbineId) return;
            newlySelected = f;

            newlySelected.setStyle(turbineStyling(newlySelected, map).selected);
            setHighlighted(newlySelected);
            return true;
        });

        if (newlySelected) {
            const turbineId = newlySelected.get('turbine_id')
            if (!turbineId) return;
            setSelectedTurbine(turbines.find(t => t.id === turbineId))
        } else {
            setSelectedTurbine(null);
        }
    }, [highlighted, turbineStyling, map, turbines]);

    // if only one turbine, show lightning map
    useEffect(() => {
        if (!initialized) return;
        setLayerSettings(prev => ({
            ...prev,
            lightning: turbines.length === 1
        }))
    }, [turbines, initialized]);

    // set up and destroy mouse callbacks
    useEffect(() => {
        if (!initialized) return;

        const w = evt => {
            if (forceShiftkeyToZoom && !evt.originalEvent.shiftKey) {
                setShowHoldShiftToZoomPopup(true);
                evt.stopPropagation();
                return;
            }
            setShowHoldShiftToZoomPopup(false);
        }

        map.on('wheel', w)
        map.on('click', handleOnClick);
        map.on('pointermove', handleOnPointerMove)

        return () => {
            map.un('wheel', w)
            map.un('click', handleOnClick)
            map.un('pointermove', handleOnPointerMove)
        }
    }, [initialized, map, handleOnClick, handleOnPointerMove]);

    const turbineSource = useMemo(() => {
        if (!turbines || turbines.length === 0) return null;
        const turbineFeatures = turbines.map(t => {
            const url = getIconForTurbine(t, layerSettings.most_probable)
            const f = new Feature({
                geometry: new Point(fromLonLat([t.lon, t.lat], 'EPSG:3857')),
                name: t.name,
                turbine_id: t.id,
                status_icon: url,
                canHover: true,
            })
            f.setStyle(turbineStyling(f, map).normal);

            return f
        }
        )
        return new VectorSource({
            features: turbineFeatures
        })
    }, [turbines, turbineStyling, layerSettings, map]);

    const collectionAreaSource = useMemo(() => {
        if (!turbines || turbines.length === 0) return null;
        const collectionAreaFeatures = turbines.map(t => {
            const f = new Feature({
                geometry: new Polygon([t.collection_area.map(c => fromLonLat([c.lon, c.lat], 'EPSG:3857'))]),
            })
            f.setStyle(new Style({
                fill: new Fill({
                    color: 'rgba(115,142,218, 1)' // sets the opacity on the entire layer instead of individually, to easily dissolve
                })
            }));
            return f
        }
        )
        return new VectorSource({
            features: collectionAreaFeatures
        })
    }, [turbines, turbineStyling, layerSettings, map]);

    // set source for first render (turbineSource)
    useEffect(() => {
        if (!turbineSource) return;
        setInitialSourceRender(turbineSource)
    }, [turbineSource]);

    const lightningGroups = useMemo(() => {
        if (lightning_strikes === null) return null;


        const lightningGroups = {}
        const allLightningOut = [];
        const allLightningMostProb = [];
        const allLightningStrikes = [];
        const usedLightningIds = [];
        turbines.forEach(turbine => {
            const allTurbineStrikes = [];
            const outsideIec = [];
            const turbineMostProbable = [];
            const turbineMostProbableOutsideIec = [];
            lightning_strikes
                .filter(tl => tl.turbine_id === turbine.id)
                .map(tl => {
                    const f = {
                        type: "Feature",
                        properties: {},
                        geometry: {
                            type: "Polygon",
                            coordinates: [tl.confidence_ellipse.map(c => fromLonLat([c.lon, c.lat], 'EPSG:3857'))]
                        }
                    }

                    if (!usedLightningIds.includes(tl.id)) {
                        allLightningStrikes.push(f)
                        usedLightningIds.push(tl.id)
                    }

                    if (tl.most_probable) {
                        if (tl.outside_certification) {
                            allLightningOut.push(f)
                        }
                        // since this layer shows all lightning - we can use most_probable to only select one turbine_lightning for each lightning
                        allLightningMostProb.push(f)

                        turbineMostProbable.push(f)
                        if (tl.outside_certification) {
                            turbineMostProbableOutsideIec.push(f);
                        }
                    }

                    if (tl.outside_certification) {
                        outsideIec.push(f)
                    }
                    allTurbineStrikes.push(f)
                })

            lightningGroups[turbine.id] = {
                all: createSourceFromFeatures(allTurbineStrikes),
                outside: createSourceFromFeatures(outsideIec),
                most_probable: createSourceFromFeatures(turbineMostProbable),
                most_probable_outside_iec: createSourceFromFeatures(turbineMostProbableOutsideIec),
            }
        })
        lightningGroups['unified'] = {
            all: createSourceFromFeatures(allLightningStrikes),
            outside: createSourceFromFeatures(allLightningOut),
            most_probable: createSourceFromFeatures(allLightningMostProb),
            most_probable_outside_iec: createSourceFromFeatures(allLightningOut),
        }
        return lightningGroups
    }, [turbines, lightning_strikes]);

    useEffect(() => {
        const res = {}
        turbines.forEach(turbine => {
            res[turbine.id] = new VectorLayer({
                source: createSourceFromFeatures([{
                    type: "Feature",
                    properties: {},
                    geometry: {
                        type: "Polygon",
                        coordinates: [turbine.collection_area.map(c => fromLonLat([c.lon, c.lat], 'EPSG:3857'))]
                    }
                }]),
                style: [
                    new Style({
                        stroke: new Stroke({
                            color: "black",
                            width: 1
                        }),
                        fill: new Fill({
                            color: 'rgba(115,142,218, 0.3)'
                        })
                    })
                ]
            })
        })
        setLayers(prev => ({
            ...prev,
            turbine_collection_area: res,
        }))
    }, [turbines]);

    // update lightning layers if lightning groups change
    useEffect(() => {
        if (!lightningGroups) return;

        const turbineLightningLayers = {}
        Object.keys(lightningGroups).forEach(turbine_id => {
            const {
                outside,
                all,
                most_probable,
                most_probable_outside_iec,
                collection_area
            } = lightningGroups[turbine_id]
            if (layerSettings.only_show_outside_iec) {
                // if (outside.length === 0) return;
            }

            let relevant = null;
            if (layerSettings.most_probable) {
                if (layerSettings.only_show_outside_iec) {
                    relevant = most_probable_outside_iec
                } else {
                    relevant = most_probable
                }
            } else {
                if (layerSettings.only_show_outside_iec) {
                    relevant = outside
                } else {
                    relevant = all
                }
            }

            turbineLightningLayers[turbine_id] = new VectorLayer({
                source: relevant,
                style: [
                    new Style({
                        stroke: new Stroke({
                            color: "black",
                            width: 1
                        }),
                        fill: new Fill({
                            color: "rgba(255, 0, 0, 0.2)"
                        })
                    })
                ]
            })
        })
        setLayers(prev => ({
            ...prev,
            lightning: turbineLightningLayers,
        }))
    }, [lightningGroups, layerSettings]);

    const heatmapLayerSource = useMemo(() => {
        if (!lightning_strikes) return null;

        const lightningFeatures = lightning_strikes.map(t => new Feature({
            geometry: new Point(fromLonLat([t.lon, t.lat], 'EPSG:3857')),
            peak_current: t.peak_current
        }))

        return new VectorSource({
            features: lightningFeatures
        })
    }, [lightning_strikes]);

    // set layers
    useEffect(() => {
        if (!heatmapLayerSource || !turbineSource) return;

        const initTurbineLayer = new VectorLayer({
            source: turbineSource
        })

        const heatmapLayer = new Heatmap({
            source: heatmapLayerSource,
            blur: 25,
            radius: 15,
            weight: function (feature) {
                let peak_current = feature.get('peak_current');
                peak_current = parseFloat(peak_current)
                // Normalize value (values exceeding 1 are clamped to 1 by OpenLayers)
                return (Math.abs(peak_current) / 100)
            }
        })

        setLayers(prev => ({
            ...prev,
            turbines: initTurbineLayer,
            heatmap: heatmapLayer,
            backgroundImage: tileLayerFromUrl('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'),
            backgroundTopo: tileLayerFromUrl('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}'),
            collection_area: new VectorLayer({
                source: collectionAreaSource,
                opacity: 0.3
            })
        }))
    }, [heatmapLayerSource, turbineSource, collectionAreaSource]);

    // Update turbine colors based on selected turbine legend
    useEffect(() => {
        if (!initialized) return;
        const layers = map.getLayers()
        if (!layers) return;
        const newTurbineLayer = new VectorLayer({
            source: turbineSource
        })
        setLayers(prev => ({ ...prev, turbines: newTurbineLayer }))
    }, [map, initialized, turbineSource]);

    // Render layers from settings
    useEffect(() => {
        if (!initialized || !layers.lightning) return;

        const selectedLayers = []

        if (layerSettings.background === 'topographic') {
            selectedLayers.push(layers.backgroundTopo)
        } else {
            selectedLayers.push(layers.backgroundImage)
        }

        if (layerSettings.collection_area) {
            selectedLayers.push(layers.collection_area)
        }

        if (selectedTurbine) {
            selectedLayers.push(layers.turbine_collection_area[selectedTurbine.id])
            selectedLayers.push(layers.lightning[selectedTurbine.id])
        } else {
            if (layerSettings.lightning) {
                selectedLayers.push(layers.lightning.unified)
            }
        }

        if (layerSettings.heatmap) {
            selectedLayers.push(layers.heatmap)
        }

        if (layerSettings.turbines) {
            selectedLayers.push(layers.turbines)
        }

        map.setLayers(selectedLayers)
    }, [map, layerSettings, initialized, layers, selectedTurbine]);




    const fetchCreateUserAction = ({ windfarm_id, turbine_ids, action, user_comment }) => {
        createUserAction({ turbine_ids, action, target_date: null, user_comment: null })
    }

    return (


        <div className='ol-map-parent'>
            {qaOpen.status && qaOpen.type !== '' && <MapQa qaOpen={qaOpen} setQaOpen={setQaOpen} />}

            <div ref={mapElement} key='main-wf-map' className='ol-map-container'></div>

            {selectedTurbine && <div className='selected-turbine'>
                <div className='title-wrapper'>
                    <h2>{selectedTurbine.name}</h2>

                    <div className='status-icon-wrapper'>
                        <img className='turbine-status-icon'
                            src={getIconForTurbine(selectedTurbine, layerSettings.most_probable)}
                            alt={selectedTurbine.status} />
                        <sub>{{
                            'acceptable_risk': 'Managed',
                            'at_risk': 'At risk',
                            'marked_for_inspection': 'Marked for inspection'
                        }[selectedTurbine.status]
                        }</sub>
                    </div>
                </div>
                <TurbineLightningRuler
                    strikes={selectedTurbine.turbine_lightning
                        .filter(tl => !layerSettings.most_probable || tl.most_probable)
                        .filter(tl => !layerSettings.only_show_outside_iec || tl.outside_certification)
                    } />

                <div className="map-pop-up-table-container">
                    <div className="table-scroll">
                        <table>
                            <thead>
                                <tr>
                                    <th>Distance</th>
                                    <th>Peak</th>
                                    <th>Date</th>
                                    <th>IEC</th>
                                    <th>
                                        <div
                                            onClick={() => setShowLightningTable(prev => !prev)}
                                            className={`pop-up-arrow-icon ${showLightningTable ? 'open' : 'closed'}`}
                                        ></div>
                                    </th>
                                </tr>
                            </thead>
                            {showLightningTable && (
                                <tbody>
                                    {
                                        [...selectedTurbine.turbine_lightning]
                                            .filter(tl => !layerSettings.most_probable || tl.most_probable)
                                            .filter(tl => !layerSettings.only_show_outside_iec || tl.outside_certification)
                                            .sort((a, b) => a.distance_km - b.distance_km)
                                            .map(tl => (
                                                <tr
                                                    className={`lightning-row ${tl.outside_certification ? 'outside-cert' : ''}`}
                                                    key={tl.id}
                                                >
                                                    <td>{Math.round(tl.distance_km * 100) * 10} m</td>
                                                    <td>{Math.round(tl.peak_current * 10) / 10} kA</td>
                                                    <td>{toPrettyDateStr(new Date(tl.timestamp), true)}</td>
                                                    {tl.outside_certification ? <td>Outside certification</td> : <td>{''}</td>}
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            )}
                        </table>
                    </div>

                    {selectedTurbine.ping_measurements?.length > 0 && (
                        <div className="table-scroll sensor-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Sensor measurement</th>
                                        <th>
                                            <div
                                                onClick={() => setShowSensorTable(prev => !prev)}
                                                className={`pop-up-arrow-icon ${showSensorTable ? 'open' : 'closed'}`}
                                            ></div>
                                        </th>
                                    </tr>
                                </thead>
                                {showSensorTable && (
                                    <tbody>
                                        {
                                            selectedTurbine.ping_measurements.map((pm, i) => (
                                                <tr key={i}>
                                                    <td>{toPrettyDateStr(new Date(pm), true)}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                )}
                            </table>
                        </div>
                    )}
                </div>







                <div style={{ flexGrow: 1 }} />
                {selectedTurbine.status === 'at_risk' && <>
                    <WplButton className='user-action-btn' value='Mark for Inspection'
                        onClick={() => {
                            fetchCreateUserAction({
                                windfarm_id: selectedTurbine.windfarm_id,
                                turbine_ids: [selectedTurbine.id],
                                action: 'marked_for_inspection',
                                user_comment: ''
                            })
                        }} big />
                    <WplButton className='user-action-btn ignore' value='Ignore Risk' red
                        onClick={() => {
                            fetchCreateUserAction({
                                windfarm_id: selectedTurbine.windfarm_id,
                                turbine_ids: [selectedTurbine.id],
                                action: 'acceptable_risk',
                                user_comment: ''
                            })
                        }} big style={{ fontSize: '1em' }} />
                </>}
            </div>}
            {initialized && <MapReferences popoutReferencesOpen={popoutReferencesOpen}
                setPopoutReferencesOpen={setPopoutReferencesOpen}
                setQaOpen={setQaOpen}
                setPopoutSettingsOpen={setPopoutSettingsOpen}
                popoutSettingsOpen={popoutSettingsOpen} />}

            {initialized && <MapSettings
                layerSettings={layerSettings}
                setLayerSettings={setLayerSettings}
                popoutSettingsOpen={popoutSettingsOpen}
                setPopoutSettingsOpen={setPopoutSettingsOpen}
                setQaOpen={setQaOpen}
                setPopoutReferencesOpen={setPopoutReferencesOpen}
                popoutReferencesOpen={popoutReferencesOpen}
            />
            }

            {showHoldShiftToZoomPopup && <div className='hold-shift-to-zoom-popup'>
                <p>Hold shift to zoom</p>
            </div>}
        </div>
    )
}